<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
     
        <div class="col-xl-12">
          <h2 class="font-rubik">Revolutionize Robotics Learning- Collaborate, Create, Innovate!</h2>
        </div>
        <div class="col-xl-12 mt-20">
          <p class="font-rubik">Welcome to Orbital, the pioneering cloud-based platform transforming how educational robots are programmed and shared.
             We're not just a service; we're a community-driven hub where innovation meets collaboration, tailor-made for the robotics enthusiasts
              of tomorrow.</p>
        </div>
        <div class="col-xl-12 mt-20">
          <h4 class="font-rubik">Ready to Elevate Your Robotics Learning?</h4>
        </div>
        <div class="col-xl-12">
          <p class="font-rubik">Orbital  the Future of Robotics Education - Cloud-Based, Collaborative, and Interactive!</p>

          <router-link to="/contact-us-cs" class="theme-btn-one btn-lg mt-50 md-mt-30">Contact US</router-link>
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>