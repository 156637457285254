<template>
  <div>
    <!-- Your existing content goes here -->

    <!-- Sticky white box -->
    <div class="sticky-box d-none d-md-block" v-show="showStickyBox">
      <h3>{{coursedata.title}}</h3>
      <ul class="style-none product-feature">
         <li v-for="include in coursedata.include" :key="include" >{{ include }}</li>
         </ul>
         <div class="row">
            
            <div class="col-6 courseprice">
              <div v-if="courseprice === 50" class="price  line-through font-rubik">
                Price: Є{{ courseprice }}
                  </div>
                  <!-- Conditionally render the half price in a different color -->
                  <div v-if="courseprice === 50" class="price half-price font-rubik">
                    Є{{ courseprice / 2 }}
                  </div>
                  <!-- Render the normal price when it is not equal to 50 -->
                  <div v-else class="price font-rubik">
                    Є{{ courseprice }} 
                  </div>
              
              </div>
            <a v-if="courseprice  == 50" href="https://iyzi.link/AJZ38g" class="col-6 theme-btn-one btn-lg mt-20 md-mt-20">Enroll Now</a>
            <a v-if="courseprice  >= 300" href="" class="col-6 theme-btn-one btn-lg mt-20 md-mt-20">Pre Order</a>

        <a  v-if="courseprice  == 0"  href="/register" class="col-6 theme-btn-one btn-lg mt-20 md-mt-20">Register</a>         </div>
       
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
 
  data() {
    return {
      showStickyBox: true,
      coursedata: [],
            courseprice: [],
    };
  },
  methods: {
    hideStickyBox() {
      this.showStickyBox = false;
    },
  },
  async mounted() {
        const courseId = this.$route.query.id;

        try {
            const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/courses');
            console.log(response);
            // Assuming that you want to display the lessons of the first course
            //   const firstCourse = courseId; // Get the first course from the array
            const courses = response.data.data;
            const firstCourse = courses.find(course => course.id.toString() === courseId);

            if (firstCourse) {
                this.coursedata = firstCourse.attributes.data; // Assign the data property of the first course to coursedataata
                this.courseprice = firstCourse.attributes.price;
            }
            console.log(this.coursedataata);

        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    },
};
</script>
