<template>
  <SignUpMain/>
</template>

<script>
import SignUpMain from '../components/sign-up';

export default {
  name:'SignUp',
  components:{SignUpMain}
}
</script>