<template>
    <div class="main-page-wrapper overflow-hidden">
      <HeroArea/>
      <FaqArea/>
    </div>
  </template>
  
  <script>
  import HeroArea from './hero-area.vue';
  import FaqArea from './commiunity-area.vue';
  
  export default {
      name: "FaqsMain",
      components: { HeroArea,FaqArea }
  }
  </script>