<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <Header />

    <!-- Main content area with sidebar -->
    <div class="service-details-one pt-200 md-pt-150">
        <div class="row px-4">
          <!-- Sidebar -->
          <div class="col-xl-2 col-lg-2 col-md-3 order-lg-first">
            <div class="sidebar md-mt-100">
              <div class="category-list">
                <h4 class="font-gordita dashboard_menue">Menu</h4>
                <ul>
                  <li @click="navigateToDashboard('courses')">
                    <img src="../../assets/images/icon/course.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'courses' }">Products</a>
                  </li>
                  <li @click="navigateToDashboard('learning')">
                    <img src="../../assets/images/icon/robot.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'learning' }">Learning</a>
                  </li>
                  <li @click="navigateToDashboard('simulator')">
                    <img src="../../assets/images/icon/simulation.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'simulator' }">Online Simulator</a>
                  </li>
                  <li @click="navigateToDashboard('orbital')">
                    <img src="../../assets/images/icon/unlock.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'orbital' }">Orbital</a>
                  </li>
                  <li @click="navigateToDashboard('community')">
                    <img src="../../assets/images/icon/group.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'community' }">Community</a>
                  </li>
                  <li @click="navigateToDashboard('profile')">
                    <img src="../../assets/images/icon/user.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'profile' }">Profile</a>
                  </li>
                </ul>
              </div>
              <div class="sidenote">
                <p>Empowering Your Robotics Journey - Code, Connect, Create!</p>
                <span>- Roboquark</span>
              </div>
            </div>
          </div>

          <!-- Main content -->
          <div class="col-xl-10 col-lg-10 order-lg-last">
            <div class="project-container">
              <h2 class="font-rubik">Arduino Servo and LED Control with Ultrasonic Sensor</h2>
              <div class="learning-material">
                <h3 class="font-rubik pt-50">Circuit Overview</h3>
                <p>
                  This project uses an Arduino to control two servo motors and two LEDs based on distance measurements from an ultrasonic sensor. When an object is detected within 150 centimeters, the yellow LED lights up, and the right servo motor moves to 90 degrees. If the object is farther than 150 centimeters, the red LED lights up, and the left servo motor moves to 90 degrees.
                </p>

                <h4>Components Used</h4>
                <ul>
                  <li>Arduino Uno (or compatible board)</li>
                  <li>Ultrasonic Sensor HC-SR04</li>
                  <li>Two Servo Motors</li>
                  <li>Two LEDs (Yellow and Red)</li>
                  <li>Two 220-ohm Resistors</li>
                </ul>

                <h4>How the Circuit Works</h4>
                <h5>Ultrasonic Distance Measurement:</h5>
                <ul>
                  <li>The HC-SR04 sensor emits ultrasonic waves through the Trig pin.</li>
                  <li>It listens for the echo through the Echo pin.</li>
                  <li>The time taken for the echo to return is measured and converted into distance.</li>
                </ul>

                <h5>LED Indicators:</h5>
                <ul>
                  <li><strong>Yellow LED</strong>: Lights up when an object is within 150 cm, indicating proximity.</li>
                  <li><strong>Red LED</strong>: Lights up when the object is farther than 150 cm, indicating distance.</li>
                </ul>

                <h5>Servo Motor Control:</h5>
                <ul>
                  <li><strong>Right Servo Motor</strong>: Moves to 90 degrees when an object is close (≤ 150 cm).</li>
                  <li><strong>Left Servo Motor</strong>: Moves to 90 degrees when the object is far (> 150 cm).</li>
                  <li>If no valid distance is detected, both servos return to their initial 0-degree positions.</li>
                </ul>

                <h4>Serial Monitor:</h4>
                <p>Displays real-time distance measurements for monitoring and debugging.</p>

                <h4>Example Robotics Applications</h4>
                <ul>
                  <li><strong>Obstacle Avoidance Robot</strong>: Navigates around obstacles by detecting their distance and adjusting their path using servo motors.</li>
                  <li><strong>Automated Sorting System</strong>: Sorts objects on a conveyor belt based on their distance from the sensor, directing them to different bins.</li>
                  <li><strong>Interactive Display Systems</strong>: Detects the presence of viewers, activates servos to move display parts, and lights up LEDs to highlight information based on interaction.</li>
                </ul>

                <h4>Simulating the Circuit</h4>
                <p>
                  Using the online simulator, you can visualize and test this setup without physical components. The simulation allows you to:
                </p>
                <ul>
                  <li>Adjust Distance Parameters: Modify the 150 cm threshold to observe different responses.</li>
                  <li>Monitor Serial Output: View real-time distance readings.</li>
                  <li>Visualize Servo Movements: Watch the servos react to sensor inputs, enhancing your understanding of actuator control.</li>
                </ul>

                <h3>Let's go to the simulator and test this project!</h3>
              </div>

              <div class="iframe-container">
                <iframe src="https://wokwi.com/projects/413253476691738625" frameborder="0"></iframe>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from "../common/Headers/dashboardheader.vue";

export default {
  name: 'ServoLedControl',
  components: { Header },
  methods: {
    navigateToDashboard(section) {
      // Navigate to the dashboard and pass the section as a query parameter
      this.$router.push({ path: '/dashboard', query: { content: section } });
    }
  }
};
</script>

<style scoped>
.project-container {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.learning-material {
  margin-bottom: 20px;
  line-height: 1.8;
  font-size: 16px;
  color: #333;
}

.learning-material h3 {
  margin-top: 15px;
  color: #0056b3; /* Blue color for headers */
  font-weight: 600;
}
.learning-material h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  color: #00b3b3; /* Aqua color for headers */
  font-weight: 400;
}

.learning-material h5 {
  margin-top: 20px;
  margin-bottom: 15px;
  color: #090a0a; /* Dark color for headers */
  font-weight: 400;
}
.learning-material p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
}

.learning-material ul {
  margin-left: 20px;
  list-style-type: disc; /* Adds bullet points */
}

.learning-material li {
  margin-bottom: 12px; /* Adds spacing between list items */
  font-size: 18px;
}

.iframe-container {
  overflow: hidden;
  position: relative;
  padding-top: 30px;
  height: calc(100vh - 60px);
}

iframe {
  position: absolute;
  top: -38px;
  left: 0;
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
