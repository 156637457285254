<template>
  <div
    class="fancy-text-block-twentyThree lg-container  pt-30 md-mt-30"
    id="feature"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 " data-aos="fade-right">
          <div class="text-wrapper ps-xl-5">
            <div class="cl-text"> <span></span></div>
            <div class="title-style-twelve">
              <h2>Discover RoboQuark</h2>
              <p class="mt-35 mb-60 md-mb-40">
                We are a team of passionate robotic enthusiasts, committed to making robotics education accessible to all. Our online platform offers a variety of interactive courses and tutorials, designed to help learners of all levels master the art of robotics. Our Electron and Neutron robot packages provide hands-on learning experiences that allow users to build and program their own robots. Join us today and discover the exciting world of robotics!
              </p>
            </div>
            <a href="/register" class="theme-btn-eight">Get Start </a>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="img-container position-relative md-mt-60">
            <img
              src="../../assets/images/assets/robot.png"
              alt=""
              class="main-screen"
            />
            
         
          </div>
        </div>
      </div>
    </div>

      <div class="container ">
        <div class="row justify-content-between">
          <div
            v-for="item in textBlockData"
            :key="item.id"
            class="col-xl-3 col-md-4"
            data-aos="fade-up"
            :data-aos-delay="item.delay"
          >
            <div class="block-style-twentyEight text-center">
              <div class="icon d-flex justify-content-center align-items-end">
                <img :src="item.icon" alt="" />
              </div>
              <h4>{{ item.title }}</h4>
            </div>
            <!-- /.block-style-twentyEight -->
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
  data() {
    return {
      textBlockData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/149.svg`),
          title: "Practical",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/150.svg`),
          title: "Simple to use",
      
          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/151.svg`),
          title: "Save time & Effort",
          delay: "200",
        },
      ],
    };
  },
};
</script>
