
<template>
    
                  <div class=" meetrobot" id="robo">
                      <div class="title-style-five text-center  md-mb-30">
                          <h2>Meet Our Robots</h2>
                          <h6>GET STARTED IN MINUTES</h6>

                      </div>
  
                      <div class=" justify-content-center">
                          <div  v-for="item in fancyFeatureData" :key="item.id"  data-aos="fade-up" data-aos-duration="1200" :data-aos-delay="item.delay">
                              <div v-if="item.title== 'Beginner-Level Educational Robot: A Fun and Interactive Way to Learn Robotics and Coding!'" class="row block-style-fourteen">
                                  <div class="col-6 illustration"><img :src="item.img" alt=""></div>
                                  <div class="col-6">
                                  <div class="title">{{item.title}}</div>
                                  <p class="font-rubik">{{item.subtitle}}</p>
                                </div>
                              </div> <!-- /.block-style-fourteen -->
                          </div>
                      </div> <!-- /.row -->
                  </div>
  </template>
  
  <script>
  export default {
    name:'RobotPart',
    data () {
      return {
        fancyFeatureData:[
          {
            id:1,
            img:require(`@/assets/images/assets/orange-product.png`),
            title:'Beginner-Level Educational Robot: A Fun and Interactive Way to Learn Robotics and Coding!',
            subtitle:'Our latest robot is perfect for both beginner and intermediate-level robotics enthusiasts. With its user-friendly design and advanced features, this tool is designed to help you explore the exciting possibilities of robotics. Whether you are just starting or looking to take your skills to the next level, our robot has everything you need to succeed.',
          },
          {
            id:2,
            img:require(`@/assets/images/assets/neutron.png`),
            title:'Neutron',
            subtitle:' Coming Soon',
            delay:100
          },
          {
            id:3,
            img:require(`@/assets/images/assets/proton.png`),
            title:'Proton',
            subtitle:' Coming Soon',
            delay:200
          },
        ]
      }
    }
  }
  </script>