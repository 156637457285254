<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 m-auto">
          <h2 class="font-rubik">{{ blogdata.title }}</h2>
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FancyHero',
  data() {
        return {
            blogdata: [],

        };
    },
  
  async mounted() {
        const blogId = this.$route.query.id;

        try {
            const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/blogs');
            console.log(response);
            // Assuming that you want to display the lessons of the first course
            //   const firstCourse = courseId; // Get the first course from the array
            const blogs = response.data.data;
            const firstblog = blogs.find(blog => blog.id.toString() === blogId);
            console.log("first"+this.firstblog);

            if (firstblog) {
                this.blogdata = firstblog.attributes; // Assign the data property of the first course to coursedataata
               
            }
            console.log(this.blogdata);

        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    },
}
</script>