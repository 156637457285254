<template>
      <div class="video">
       
  
        <div class="video-wrapper">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GVtcsYj6kQ4?si=AF8tco7pPxTSpFoD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div> <!-- /.video-wrapper -->
      </div>
  
  </template>
  
  <script>
  
  export default {
  name: 'VideoContainer',
};
  </script>