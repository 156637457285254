<template>
    <div class="row ">
        <div v-for="enrollment in matchedEnrollments" :key="enrollment.id" class="col-lg-6 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
            <div class="block-style-five" >
                <div class="icon"><img src="../../assets/images/assets/bannerpic.png" alt=""></div>
                <h6 class="enrolltitle"><span> {{ enrollment.attributes.course.data.attributes.title }}</span></h6>
                <p>{{ enrollment.attributes.course.data.attributes.data.description }}</p>
                <router-link
                  :to="`/learning?id=${ enrollment.attributes.course.data.id}`">
                <a href="" class=" theme-btn-one  mt-20 md-mt-20">Continue Learning</a>
               </router-link>

            </div> <!-- /.block-style-five --> 
        </div>
      
</div></template>
<script>
import axios from 'axios';
// for query from strapi we use this both
import { ref, onMounted } from 'vue';
const courses = ref([]);

export default {
  name: 'Enrolleds',
  setup() {
    const matchedEnrollments = ref([]);
    //userdata who we store in log in page 
    const userinfo = JSON.parse(localStorage.getItem('userInfo'));

    const fetchEnrollments = async () => {
      try {
        // Replace 'http://localhost:1337' with your actual Strapi base URL
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}api/enrolleds?filters[username][$eq]=${userinfo.username}&populate=course`);
        console.log("response");

        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('data', data); // Log the actual data

        matchedEnrollments.value = data.data;
        console.log('data', matchedEnrollments.value); // Log the actual data

      } catch (error) {
        console.error('There was a problem retrieving the enrolled data:', error);
      }
    };

    onMounted(() => {
     
        fetchEnrollments();
      
    });

    return {
      matchedEnrollments
    };
  },
}
</script>