
<template>
  <div class="
   banner d-flex md-mb-100">
      <div class="row">
        <div class="col-lg-6 col-md-12" data-aos="fade-right">
          <div class="text-wrapper ps-xl-5">
            <div class="title-style-twelve">
              <h2>Experience the Excitement of Robotics with Us!</h2>
              <p class="mt-5  md-mb-40">
                Let's start your journey towards becoming a robotics engineer!
              </p>
              <a href="/register" class="theme-btn-eight">Get Start </a>

            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" data-aos="fade-left">
          <div class=" img-container position-relative md-mt-60">
            <img src="../../assets/images/assets/bannerpic.png" alt="" class="main-screen" />


          </div>
        </div>
      </div>
  </div>
</template>
  
<script>
export default {
  name: 'BannerPart',

}
</script>