<template>
  <div class=" pb-160 md-pt-100 md-pb-100">
    <div class="container">
     

      <div class="wrapper">
        <div class="row justify-content-center">

          <div v-for="item in text_block_data" :key="item.id" class="col-lg-4 col-md-6" 
          data-aos="fade-up" data-aos-duration="1200">
            <div class="block-style-four">
              <div class="icon"><img :src="item.icon" alt=""></div>
              <h4>{{item.title}}</h4>
              <p class="font-rubik">{{item.subtitle}} </p>
              <a href="#"><i class="flaticon-right-arrow"></i></a>
            </div> <!-- /.block-style-four -->
          </div>
     
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlockTwo',
  data () {
    return {
      text_block_data : [
        {
          id:1,
          icon:require(`@/assets/images/icon/12.svg`),
          title:'Collaborative Coding Experience to Robotics',
          subtitle:'Collaborative and version-controlled environment reminiscent of popular coding repositories, tailored specifically for the world of educational robotics'
        },
        {
          id:2,
          icon:require(`@/assets/images/icon/13.svg`),
          title:'Cloud-Based Robotics Interface',
          subtitle:' Accessible remotely, we ensure you can work on your robotics projects from anywhere, anytime.'
        },
        {
          id:3,
          icon:require(`@/assets/images/icon/14.svg`),
          title:'Unified Robotics Collaboration Hu',
          subtitle:'Users can engage in real-time collaboration, share resources, and gain insights from a global community of robotics enthusiasts and educators.'
        },
      ]
    }
  }
}
</script>