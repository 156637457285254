<template>
    <Contact/>
  </template>
  
  <script>
  import Contact from '../components/common/contact-style.vue';
  
  export default {
    name:'Blog',
    components:{Contact}
  }
  </script>