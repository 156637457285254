<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <HeaderFive :doc_signature="true" />
    <HeroBanner />
    <VideoContainer />
    <Course />

    <FancyFeature2 />
    <FancyTextBlock />
    <RobotPart />
    <UseableTools />
    <BannerPart />
    <TeamMember />

    <FaqArea />
    <ShortBanner />
    <FooterSeven />
  </div>
</template>

<script>
import HeaderFive from "../common/Headers/HeaderFive.vue";
import HeroBanner from "./HeroBanner.vue";
import VideoContainer from "./Video-Container.vue";
import FancyFeature2 from "./FancyFeature-2.vue";
import FancyTextBlock from "./Fancy-text-block.vue";
import RobotPart from "./robots.vue";

import UseableTools from "./Useable-tools.vue";
import BannerPart from "./banner.vue";
import TeamMember from "./team.vue";
import FaqArea from "./FaqArea.vue";

import ShortBanner from "./ShortBanner.vue";
import FooterSeven from "../common/Footers/FooterSeven.vue";
import Course from "./courses.vue";
export default {
  name: "DocSignatureMain",
  components: {
    HeaderFive,
    HeroBanner,
    VideoContainer,
    FancyFeature2,
    FancyTextBlock,
    RobotPart,
    UseableTools,
    BannerPart,
    TeamMember,
    FaqArea,
    Course,
    ShortBanner,
    FooterSeven,
  },
};
</script>
