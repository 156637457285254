<template>
  <div class="faqs-inner-page">
    <img src="../../assets/images/icon/66.svg" alt="" class="shapes shape-one">
    <div class="shapes shape-two"></div>
    <div class="shapes shape-three"></div>
    <div class="shapes shape-four"></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-11 m-auto">
          <div class="faqs-header pb-60 md-pb-50">
           
          </div> <!-- /.faqs-header -->


          <div class="all-faqs">
            <div class="faqs-all-qus">

              <div v-for="faq in faqs" :key="faq.id" class="article-preview d-flex">
                <router-link :to="`/faq-details/${faq.id}`">

                  <div class=" avatar-info">  <img :src="getImageUrl(faq.user)" alt="" class="avatar-img">
                 </div>
                  <div>
                    <h3 class="font-rubik">{{ faq.question }}</h3>

                  </div>
                </router-link>
              </div> <!-- /.article-preview -->

            </div> <!-- /.faqs-all-qus -->
          </div> <!-- /.all-faqs -->

          <div class="text-center more-faq-ask">
            <h3 class="mb-35 font-rubik">Don’t find your answer?</h3>
            <router-link to="/contact" class="theme-btn-one">Contact us</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FaqArea',
  data() {
    return {
      faqs: []
    };
  },
  created() {
    this.fetchFaqs();
  },
  methods: {
    async fetchFaqs() {
      try {
        console.log(process.env.VUE_APP_STRAPI_URL + 'api/faqs');

        const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/faqs');
        this.faqs = response.data.data.map(item => ({
          id: item.id,
          user: item.attributes.question.user,
          question: item.attributes.question.question,
        }));     
           console.log(response.data);
           console.log(this.faqs);

      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    },
    getImageUrl(user) {
      return require(`@/assets/images/gallery/${user}.png`);
    }
  }
}
</script>
