<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <Header />

    <!-- Main content area with sidebar -->
    <div class="service-details-one pt-200 md-pt-150">
        <div class="row px-4">
          <!-- Sidebar -->
          <div class="col-xl-2 col-lg-2 col-md-3 order-lg-first">
            <div class="sidebar md-mt-100">
              <div class="category-list">
                <h4 class="font-gordita dashboard_menue">Menu</h4>
                <ul>
                  <li @click="navigateToDashboard('courses')">
                    <img src="../../assets/images/icon/course.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'courses' }">Products</a>
                  </li>
                  <li @click="navigateToDashboard('learning')">
                    <img src="../../assets/images/icon/robot.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'learning' }">Learning</a>
                  </li>
                  <li @click="navigateToDashboard('simulator')">
                    <img src="../../assets/images/icon/simulation.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'simulator' }">Online Simulator</a>
                  </li>
                  <li @click="navigateToDashboard('orbital')">
                    <img src="../../assets/images/icon/unlock.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'orbital' }">Orbital</a>
                  </li>
                  <li @click="navigateToDashboard('community')">
                    <img src="../../assets/images/icon/group.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'community' }">Community</a>
                  </li>
                  <li @click="navigateToDashboard('profile')">
                    <img src="../../assets/images/icon/user.png" style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'profile' }">Profile</a>
                  </li>
                </ul>
              </div>
              <div class="sidenote">
                <p>Empowering Your Robotics Journey - Code, Connect, Create!</p>
                <span>- Roboquark</span>
              </div>
            </div>
          </div>

          <!-- Main content -->
          <div class="col-xl-10 col-lg-10 order-lg-last">
            <div class="project-container">
              <h2 class="font-rubik">Arduino Traffic Light Control</h2>
              <div class="learning-material">
                <h3 class="font-rubik pt-50">Circuit Overview</h3>
                <p>
                  This project uses an Arduino to control three LEDs—Red, Yellow, and Green—to simulate a traffic light system. The LEDs light up in sequence to represent "Stop," "Get Ready," and "Go," providing clear visual indicators of different traffic states.
                </p>

                <h4>Components Used</h4>
                <ul>
                  <li>Arduino Uno (or compatible board)</li>
                  <li>Three LEDs (Red, Yellow, Green)</li>
                  <li>Three 220-ohm Resistors</li>
                </ul>

                <h4>How the Circuit Works</h4>
                <h5>LED Configuration:</h5>
                <ul>
                  <li><strong>Red LED (Pin 8)</strong>: Indicates "Stop."</li>
                  <li><strong>Yellow LED (Pin 9)</strong>: Indicates "Get Ready."</li>
                  <li><strong>Green LED (Pin 10)</strong>: Indicates "Go."</li>
                </ul>

                <h5>Sequential Lighting:</h5>
                <ul>
                  <li><strong>Red LED ON</strong>: Activates Red LED; turns off Yellow and Green LEDs.</li>
                  <li><strong>Yellow LED ON</strong>: After 3 seconds, activates Yellow LED; turns off Red and Green LEDs.</li>
                  <li><strong>Green LED ON</strong>: After another 3 seconds, activates Green LED; turns off Red and Yellow LEDs.</li>
                  <li><strong>Loop</strong>: Repeats the sequence indefinitely.</li>
                </ul>

                <h4>Serial Monitor:</h4>
                <p>Displays the current state of each LED for monitoring and debugging.</p>

                <h4>Example Robotics Applications</h4>
                <ul>
                  <li><strong>Obstacle Avoidance Robot</strong>: Uses LED indicators to signal navigation adjustments.</li>
                  <li><strong>Automated Sorting System</strong>: Visual signals guide sorting mechanisms based on object distance.</li>
                  <li><strong>Interactive Display Systems</strong>: LEDs provide visual feedback based on viewer interactions.</li>
                </ul>

                <h4>Simulating the Circuit</h4>
                <p>
                  Using the online simulator, you can visualize and test this setup without physical components. The simulation allows you to:
                </p>
                <ul>
                  <li>Adjust Timing Parameters: Change the 3000 ms delay to alter LED durations.</li>
                  <li>Monitor Serial Output: View status messages indicating active LEDs.</li>
                  <li>Visualize LED Sequences: Observe the correct order of LED activations, mimicking a real traffic light cycle.</li>
                </ul>

                <h3>Let's go to the simulator and test this project!</h3>
              </div>

              <div class="iframe-container">
                <iframe src="https://wokwi.com/projects/413245710717081601" frameborder="0"></iframe>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from "../common/Headers/dashboardheader.vue";

export default {
  name: 'TrafficLightControl',
  components: { Header },
  methods: {
    navigateToDashboard(section) {
      // Navigate to the dashboard and pass the section as a query parameter
      this.$router.push({ path: '/dashboard', query: { content: section } });
    }
  }
};
</script>

<style scoped>
.project-container {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.learning-material {
  margin-bottom: 20px;
  line-height: 1.8;
  font-size: 16px;
  color: #333;
}

.learning-material h3 {
  margin-top: 15px;
  color: #0056b3; /* Blue color for headers */
  font-weight: 600;
}
.learning-material h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  color: #00b3b3; /* Aqua color for headers */
  font-weight: 400;
}

.learning-material h5 {
  margin-top: 20px;
  margin-bottom: 15px;
  color: #090a0a; /* Dark color for headers */
  font-weight: 400;
}
.learning-material p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
}

.learning-material ul {
  margin-left: 20px;
  list-style-type: disc; /* Adds bullet points */
}

.learning-material li {
  margin-bottom: 12px; /* Adds spacing between list items */
  font-size: 18px;
}

.iframe-container {
  overflow: hidden;
  position: relative;
  padding-top: 30px;
  height: calc(100vh - 60px);
}

iframe {
  position: absolute;
  top: -38px;
  left: 0;
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
