<template>
    <div class="">
      <div class="container">
        <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
     
        <div class="col-xl-12">
          <h2 class="font-rubik ">Online simulator</h2>
          <h3 class="font-rubik" style="color: darkblue;font-weight: 500;margin-top: 10px;">Big News!</h3>

        </div>
        <div class="col-xl-12 ">
          <p class="font-rubik">In just 90 days, we’re launching a new, comprehensive simulator designed to elevate your robotics education experience.</p>
          <p class="font-rubik">
Until then, continue learning with our current simulator powered by Wokwi, proudly crafted by Roboquark. Keep creating, and get ready for an even better tool to support your journey!</p>
        </div>
        <p class="font-rubik" style="font-weight: 400;margin-top: 8px;">Share Your Feedback!<br>

We’d love to hear from you. Reach out at <strong style="color: darkblue;">learning@roboquark.com</strong>

        </p>

       
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
        <div class="row">
          <div v-for="(courseitem, index) in courseitems" :key="index" class="feature-blog-one">
            <div v-if="courseitem.title != 'RoboBook - Explore robotics'" class="post-meta row" data-aos="fade-up" data-aos-duration="1200" :data-aos-delay="100">
              <div class="col-4">
                <img src="../../assets/images/assets/bannerpic.png" alt="" class="image-meta">
              </div>
              <div class="col-8" style="margin-top: 6%;">
                <h3>
                  <router-link :to="courseitem.link" class="title">{{ courseitem.title }}.</router-link>
                </h3>
              </div>
              <div class="row course-desc">
                <div class="col-6">
                  <p>{{ courseitem.content }}</p>
                </div>
                <div class="col-3">
                  <router-link :to="courseitem.link">
                    <a class="theme-btn-four">View Details</a>
                  </router-link>
                </div>
              </div>
            </div> <!-- /.post-meta -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Modal from '../robo/modal.vue';
  import { ref } from 'vue';
  
  export default {
    name: 'Coursse_titels',
    components: {
      Modal // Register the component
    },
    data() {
      return {
        showModal: false,
        courseitems: [
          {
            title: 'Arduino Servo and LED Control with Ultrasonic Sensor',
            content: 'Control servos and LEDs based on distance using an ultrasonic sensor.',
            link: '/project-details/servo-led'
          },
          {
            title: 'Arduino Traffic Light Control',
            content: 'Simulate a traffic light system with LEDs controlled by an Arduino.',
            link: '/project-details/traffic-light'
          },
          {
            title: 'Arduino Mini Piano',
            content: 'Play musical notes using push buttons and a speaker for an interactive experience.',
            link: '/project-details/mini-piano'
          },
          {
            title: 'Arduino Button-Controlled LCD Text Display',
            content: 'Display interactive text on an LCD using a button to toggle messages.',
            link: '/project-details/lcd-text'
          }
        ]
      };
    }
  };
  </script>
  
  <style>
  .feature-blog-one {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .feature-blog-one h3 {
    margin-bottom: 10px;
  }
  .feature-blog-one p {
    margin-bottom: 15px;
  }
  </style>
  