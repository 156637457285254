
<template>
    
                  <div class="meetteam" id="team">
                      <div class="title-style-five text-center  md-mb-30">
                          <h2>Meet Our Teaam</h2>

                      </div>
  
                      <div class="row justify-content-center team">
                          <div v-for="item in TeamData" :key="item.id" class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1200" :data-aos-delay="item.delay">
                              <div class="block-style-fourteen">
                                  <div class="illustration"><img :src="item.img" alt=""></div>
                                  <div><h3>{{item.name}}</h3></div>
                                  <div class="titlehere"><p>{{item.title}}</p></div>

                                  <p class="font-rubik">{{item.subtitle}}</p>
                              </div> <!-- /.block-style-fourteen -->
                          </div>
                      </div> <!-- /.row -->
                  </div>
  </template>
  
  <script>
  export default {
    name:'TeamMember',
    data () {
      return {
        TeamData:[
          {
            id:1,
            img:require(`@/assets/images/assets/navid.png`),
            name:'Navid K.Nejad',
            title:'Founder and CEO- Sales',
            subtitle:'Meet Navid, our CEO and Sales Manager, who brings extensive expertise in robotics and IoT to our team, with a background as a successful robotic researcher and CEO of an IoT startup and years of experience in leadership, sales, and innovation.',
            delay:100
          },
          {
            id:2,
            img:require(`@/assets/images/assets/elham.png`),
            name:'Elham Khany',
            title:' Co-founder and COO - CPO',
            subtitle:'Meet Elham, our COO who brings a wealth of experience in product management and a strong background in robotics and technology. With her strategic vision and leadership, she is driving our company to new heights in the field of robotics and AI.',
          },
          {
            id:3,
            img:require(`@/assets/images/assets/ghazal.png`),
            name:'Ghazal Pouya',
            title:'Co-founder and CMO - Head of learning',
            subtitle:'Meet Ghazal, our esteemed CMO and Head of Learning who brings a wealth of experience in robotics research and education to our team. She is dedicated to creating innovative and engaging learning experiences for our students.',
            delay:200
          },
          {
            id:4,
            img:require(`@/assets/images/assets/vahid.png`),
            name:'Vahid K.Nejad',
            title:'Co-founder and CTO - Devops',
            subtitle:'Meet Vahid, our brilliant CTO and DevOps expert. With years of experience in software engineering and robotics, he brings a wealth of knowledge to our team, particularly in the areas of AI and software development.',
            delay:200
          },
        ]
      }
    }
  }
  </script>