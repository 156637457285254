<template>
    <div class=" pt-50 pb-60  md-pb-100">
				<div class="container">
					<div class="row">
						 <!-- Use v-if to make sure lessondata is defined before trying to access its properties -->
						 <div v-if="lessondata" class="col-md-10 aos-init aos-animate" data-aos="fade-up">
          <!-- Use v-for to iterate over the lessons array inside lessondata -->
          <div v-for="lesson in lessondata.lessons" :key="lesson.name" class="block-style-thirtyTwo d-flex">
            <div class="text">
              <h4>{{ lesson.name }}</h4>
              <p>{{ lesson.description }}</p>
            </div>
          </div> <!-- /.block-style-thirtyTwo -->
        </div>
						
					</div>
				</div>
			</div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      lessondata: null ,// Set to null to indicate that it might not be loaded yet
	  courseId:[]
    };
  },
  async mounted() {
	const courseId = this.$route.query.id;

    try {
		const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/courses');
	  console.log(response);
      // Assuming that you want to display the lessons of the first course
    //   const firstCourse = courseId; // Get the first course from the array
      const courses = response.data.data;
      const firstCourse = courses.find(course => course.id.toString() === courseId);

      if (firstCourse) {
        this.lessondata = firstCourse.attributes.data; // Assign the data property of the first course to lessondata
      }
	  console.log( this.lessondata);

    } catch (error) {
      console.error('Error fetching course data:', error);
    }
  },
}
</script>