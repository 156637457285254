<template>
  <div class="faq-section-four" id="faq">
    <div class="container">
      <div class="title-style-five text-center mb-40 md-mb-60">
        <h6>FAQ</h6>
        <h2><span>Question & Answer</span></h2>
      </div>

      <div class="row">
        <div class="col-xl-9 col-lg-10 m-auto" data-aos="fade-up" data-aos-duration="1200">
          <div id="accordion" class="accordion-style-four">


            <div v-for="item in faqData" :key="item.id" class="accordion-item">
              <div class="accordion-header" :id="item.headingNum">
                <h5 class="mb-0">
                  <button class="btn btn-link accordion-button" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#' + item.collapseNum" :aria-expanded="item.expanded ? 'true' : 'false'"
                    :aria-controls="item.collapseNum">
                    {{ item.btnText }}
                  </button>
                </h5>
              </div>
              <div :id="item.collapseNum" :class="item.collapseClass" :aria-labelledby="item.headingNum"
                data-bs-parent="#accordion">
                <div class="accordion-body">
                  <p>
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
     
    </div> <!-- /.container -->
  </div>
</template>

<script>
export default {
  name: 'FaqArea',
  data() {
    return {
      faqData: [
        {
          id: 1,
          headingNum: 'headingOne',
          collapseNum: "collapseOne",
          btnText: "What is your company's mission?",
          desc: 'Our mission is to provide innovative and accessible robotics education and products that empower students and enthusiasts to explore and create in the world of robotics.',
          collapseClass: 'accordion-collapse collapse show',
          expanded: true,
        },
        {
          id: 2,
          headingNum: 'headingTwo',
          collapseNum: "collapseTwo",
          btnText: "What age groups do you cater to?",
          desc:' We offer educational resources and products for adult learners. +16',
          collapseClass: 'accordion-collapse collapse',
        },
        {
          id: 3,
          headingNum: 'headingThree',
          collapseNum: "collapseThree",
          btnText: "How can I get involved with robotics if I have no prior experience?",
          desc: 'Our courses and products are designed to be accessible to beginners with no prior experience in robotics. We offer introductory courses and beginner-friendly kits that can help you get started.',
          collapseClass: 'accordion-collapse collapse',
        },
    
      ]
    }
  }
}
</script>