<template>
  <div :class="`mt-150 doc-container ${doc_banner ? 'mt-70 sm-m0' : 'top-border'}`">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
  <!-- ****************************** DOC SIDEBAR ********************************* -->
  <div class="col-xl-4 col-md-4 doc-sidebar">
          <div class="clearfix">
            <button class="btn btn-link d-md-none collapsed" type="button" data-bs-target="#doc-sidebar-nav"
              data-bs-toggle="collapse" aria-controls="doc-sidebar-nav" aria-expanded="false"
              aria-label="Toggle docs navigation"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30"
                height="30" focusable="false">
                <title>Menu</title>
                <path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                  d="M4 7h22M4 15h22M4 23h22"></path>
              </svg></button>
          </div>
          <nav class="doc-links collapse clearfix nav-fix" id="doc-sidebar-nav">

<ul class="list-item">
  <li class="dropdown-holder">
    <h4>Getting Started</h4>
    <ul class="sub-menu show">
      <li v-for="lesson in lessons" :key="lesson.id">
        <a @click.prevent="selectLesson(lesson)" href="#">
          {{ lesson.title }}
        </a>
      </li>
    </ul>
  </li>


</ul>
</nav> <!-- /.doc-links -->
        </div> <!-- /.doc-sidebar -->
        <!-- DOC SIDEBAR -->


        <!-- DOC MAIN BODY -->
        <main class="col-xl-9 col-md-8 pt-130 doc-main-body">
          <!-- Display the content of the selected lesson -->
          <img :src="imageUrl" class="course-banner-image">
          <div v-html="renderContent(currentLesson.content)"></div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

export default {
  name: 'DocArea',
  props: { doc_banner: Boolean },

  mounted() {
    // if user was not authenticated befor redirect to login
    if (!this.isAuthenticated()) {
      this.$router.push('/login'); // Redirect to login page
    }
  },
  methods: {
    isAuthenticated() {
      const token = localStorage.getItem('jwt');
      return !!token; // Returns true if token exists, false otherwise
    },
    renderContent(content) {
      if (!Array.isArray(content)) return ''; // Ensure content is an array

      let htmlContent = '';

      content.forEach(block => {
        switch (block.type) {
          case 'paragraph':
            htmlContent += `<p>${block.children.map(child => child.text).join('')}</p>`;
            break;
          case 'heading':
            htmlContent += `<h${block.level}>${block.children[0].text}</h${block.level}>`;
            break;
          case 'list':
            const listTag = block.format === 'unordered' ? 'ul' : 'ol';
            htmlContent += `<${listTag}>${block.children.map(listItem => {
              return `<li>${listItem.children.map(child => child.bold ? `<strong>${child.text}</strong>` : child.text).join('')}</li>`;
            }).join('')}</${listTag}>`;
            break;
          case 'image':
            const imageU = block.image?.url;
            if (imageU) {
              htmlContent += `<img src="${imageU}" alt="${block.image.alternativeText}" />`;
            }
            break;
          // Add more cases as needed
        }
      });

      return htmlContent;
    },
    selectLesson(lesson) {
  this.currentLesson = lesson;

  // Check if there is an image and it has a URL
  if (lesson.image && lesson.image.data && lesson.image.data.length > 0 && lesson.image.data[0].attributes && lesson.image.data[0].attributes.url) {
    // Construct the full URL for the image
    this.imageUrl = `${process.env.VUE_APP_STRAPI_URL_IMAGE}${lesson.image.data[0].attributes.url}`;
  }
},
   
  },
  setup() {
    const lessons = ref([]);
    const currentLesson = ref({});
    const route = useRoute();
    const courseId = route.query.id;
    const currentImage = ref(null); // Initialize currentImage as null
    const imageUrl = ref('');
    onMounted(async () => {
      try {
        // Updated API call with populate parameter
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_URL}api/courses?filters[id][$eq]=${courseId}&populate=lesson&populate=lesson.image`);

        // Assuming the API returns an array with the course object containing populated lessons
        const courseData = response.data.data[0]; // Assuming there's only one course in the response
        if (courseData && courseData.attributes && courseData.attributes.lesson) {
          // Assign the populated lessons
          lessons.value = courseData.attributes.lesson;

          // Set the first lesson as the current lesson if available
          if (lessons.value.length > 0) {
            currentLesson.value = lessons.value[0];
            // Check if the current lesson has an 
            console.log(currentLesson.value.image?.data[0]?.attributes?.url);
            const firstLessonImageUrl = currentLesson.value.image?.data[0]?.attributes?.url;
            if (firstLessonImageUrl) {
              console.log("IFFFFFFFFF   "+firstLessonImageUrl);
        imageUrl.value = `${process.env.VUE_APP_STRAPI_URL_IMAGE}${firstLessonImageUrl}`;
        console.log("IFFFFFFFFF    "+imageUrl.value);

      } 

          }
          console.log(currentLesson)
        }
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    });


    return {
      lessons,
      currentLesson,
      imageUrl,

    };
  }
}
</script>
