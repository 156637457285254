import {createRouter, createWebHistory} from 'vue-router'
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import NotFound from '../pages/404';
import SignUp from '../pages/sign-up';
import Dashboard from '../pages/dashboard';
import CoursePage from '../pages/coursepage';
import LearningPage from '../pages/learningpage'
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog-details';
import FaqDetails from '../pages/faq-details';

import Contact from '../pages/contact';
import LcdTextDisplay from '../components/project-details/servo-led';
import TrafficLight from '../components/project-details/traffic-light';
import MiniPiano from '../components/project-details/mini-piano';
import LcdText from '../components/project-details/lcd-text';

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        meta: {
            title: 'RoboQuark',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: 'Contact',
        },
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
        meta: {
            title: 'Blog || Roboquark',
        },
    },
    {
        path: '/project-details/servo-led',
        name: 'LcdTextDisplay',
        component: LcdTextDisplay,
      },
      {
        path: '/project-details/traffic-light',
        name: 'TrafficLight',
        component: TrafficLight,
      },
      {
        path: '/project-details/mini-piano',
        name: 'MiniPiano',
        component: MiniPiano,
      },
      {
        path: '/project-details/lcd-text',
        name: 'LcdText',
        component: LcdText,
      },
    {
        path: '/blog-details',
        name: 'BlogDetails',
        component: BlogDetails,
        meta: {
            title: 'Blog Details || Roboquark',
        },
    },
    {
        path: '/coursedetails',
        name: 'CoursePage',
        component: CoursePage,
        meta: {
          title: 'CourseDetails',
        },
      },
      {
        path: '/faq-details/:id',
        name: 'FaqDetails',
        component: FaqDetails,
        meta: {
            title: 'FaqDetails',
          },
      },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: {
            title: 'Login',
        },
    },
    {
        path: '/register',
        name: 'SignUp',
        component: SignUp,
        meta: {
            title: 'Register',
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard',
        },
    },
    {
        path: '/learning',
        name: 'LearningPage',
        component: LearningPage,
        meta: {
          title: 'LearningPage',
        },
      },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound',
        },
    },
   
   
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
