<template>
  <HeaderFive/>
  <div class="contact-us-light pt-140 pb-200 md-pt-90 md-pb-80">
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="container">
      <div class="fancy-hero-two" style="padding-bottom: 2%;">
        <div class="bg-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-10 m-auto">
                <h1 class="heading md-p0">Contact Us</h1>
              </div>
            </div>
          </div>
        </div><!-- /.bg-wrapper -->
      </div>

      <div class="form-style-light">
        <form @submit.prevent="onSubmit" id="contact-form" data-bs-toggle="validator">
          <div class="messages"></div>
          <div class="row controls">
            <div class="col-md-6">
              <div class="input-group-meta form-group mb-35">
                <label>First Name</label>
                <input type="text" placeholder="Michel" name="Fname" required="required" data-error="Name is required.">
                <span class="placeholder_icon valid-sign">
                  <img src="../../assets/images/icon/18.svg" alt="">
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group-meta form-group mb-35">
                <label>Last Name</label>
                <input type="text" placeholder="Simon" name="Lname" required="required" data-error="Name is required.">
                <span class="placeholder_icon valid-sign">
                  <img src="../../assets/images/icon/18.svg" alt="">
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group mb-35">
                <label>Your Email</label>
                <input type="email" placeholder="gobapubo@jogi.net" name="email" required="required"
                  data-error="Valid email is required.">
                <span class="placeholder_icon valid-sign">
                  <img src="../../assets/images/icon/18.svg" alt="">
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group lg mb-35">
                <label>Your Message</label>
                <textarea placeholder="Write your message here..." name="message" required="required"
                  data-error="Please,leave us a message."></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12"><button class="theme-btn-one btn-lg">Send Message</button></div>
          </div>
        </form>
      </div> <!-- /.form-style-light -->
    </div>
  </div>
</template>

<script>
import HeaderFive from "../common/Headers/HeaderFive.vue";
import FooterSeven from "../common/Footers/FooterSeven.vue";

export default {
  name: 'ContactStyleLight',
  components: {
    HeaderFive,
    FooterSeven,
  },
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>