<template>
    <div class="main-page-wrapper p0 overflow-hidden">
      <Header />
  
      <!-- Main content area with sidebar -->
      <div class="service-details-one pt-200 md-pt-150">
          <div class="row px-4">
            <!-- Sidebar -->
            <div class="col-xl-2 col-lg-2 col-md-3 order-lg-first">
              <div class="sidebar md-mt-100">
                <div class="category-list">
                  <h4 class="font-gordita dashboard_menue">Menu</h4>
                  <ul>
                    <li @click="navigateToDashboard('courses')">
                      <img src="../../assets/images/icon/course.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'courses' }">Products</a>
                    </li>
                    <li @click="navigateToDashboard('learning')">
                      <img src="../../assets/images/icon/robot.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'learning' }">Learning</a>
                    </li>
                    <li @click="navigateToDashboard('simulator')">
                      <img src="../../assets/images/icon/simulation.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'simulator' }">Online Simulator</a>
                    </li>
                    <li @click="navigateToDashboard('orbital')">
                      <img src="../../assets/images/icon/unlock.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'orbital' }">Orbital</a>
                    </li>
                    <li @click="navigateToDashboard('community')">
                      <img src="../../assets/images/icon/group.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'community' }">Community</a>
                    </li>
                    <li @click="navigateToDashboard('profile')">
                      <img src="../../assets/images/icon/user.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'profile' }">Profile</a>
                    </li>
                  </ul>
                </div> <!-- /.category-list -->
                <div class="sidenote">
                  <p>Empowering Your Robotics Journey - Code, Connect, Create!</p>
                  <span>- Roboquark</span>
                </div>
              </div> <!-- /.sidebar -->
            </div>
  
            <!-- Main content -->
            <div class="col-xl-10 col-lg-10 order-lg-last">
              <div class="project-container">
                <h2 class="font-rubik">Arduino Mini Piano</h2>
                <div class="learning-material">
                  <h3 class="font-rubik pt-50">Circuit Overview</h3>
                  <p>
                    This project uses an Arduino to control eight buttons and a speaker, simulating a mini piano. Pressing keys 1 to 8 on your keyboard plays different musical notes, allowing you to create simple melodies.
                  </p>
  
                  <h4>Components Used</h4>
                  <ul>
                    <li>Arduino Uno (or compatible board)</li>
                    <li>Eight Push Buttons</li>
                    <li>Speaker</li>
                    <li>Eight 220-ohm Resistors</li>
                  </ul>
  
                  <h4>How the Circuit Works</h4>
                  <h5>Button Configuration:</h5>
                  <ul>
                    <li>Eight buttons are connected to digital pins 12, 11, 10, 9, 7, 6, 5, and 4, each mapped to a different musical note.</li>
                  </ul>
  
                  <h5>Sound Output:</h5>
                  <ul>
                    <li>The speaker is connected to pin 8 to play the corresponding note when a button is pressed.</li>
                  </ul>
  
                  <h4>Serial Monitor:</h4>
                  <p>Displays messages indicating which note is being played, aiding in monitoring and debugging.</p>
  
                  <h4>Example Robotics Applications</h4>
                  <ul>
                    <li><strong>Interactive Music Robots</strong>: Robots that play music based on user input.</li>
                    <li><strong>Educational Tools</strong>: Teaching programming and electronics through musical projects.</li>
                    <li><strong>Assistive Devices</strong>: Helping individuals create music through accessible interfaces.</li>
                  </ul>
  
                  <h4>Simulating the Circuit</h4>
                  <p>
                    Using the online simulator, you can visualize and test this setup without physical components. The simulation allows you to:
                  </p>
                  <ul>
                    <li>Press Virtual Buttons: Trigger different notes by pressing keys 1 to 8.</li>
                    <li>Monitor Serial Output: View messages indicating the active notes.</li>
                    <li>Hear Sounds: Listen to the tones generated by the speaker, enhancing your understanding of sound control with Arduino.</li>
                  </ul>
  
                  <h3>Let's go to the simulator and test this project!</h3>
                </div>
  
                <div class="iframe-container">
                  <iframe src="https://wokwi.com/projects/291958456169005577" frameborder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "../common/Headers/dashboardheader.vue";
  
  export default {
    name: 'MiniPiano',
    components: { Header },
    methods: {
      navigateToDashboard(section) {
        // Navigate to the dashboard and pass the section as a query parameter
        this.$router.push({ path: '/dashboard', query: { content: section } });
      }
    }
  };
  </script>
  
  <style scoped>
  .project-container {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-family: 'Roboto', sans-serif;
  }
  
  .learning-material {
    margin-bottom: 20px;
    line-height: 1.8;
    font-size: 16px;
    color: #333;
  }
  
  .learning-material h3 {
    margin-top: 15px;
    color: #0056b3; /* Blue color for headers */
    font-weight: 600;
  }
  .learning-material h4 {
    margin-top: 20px;
    margin-bottom: 15px;
    color: #00b3b3; /* Aqua color for headers */
    font-weight: 400;
  }
  
  .learning-material h5 {
    margin-top: 20px;
    margin-bottom: 15px;
    color: #090a0a; /* Dark color for headers */
    font-weight: 400;
  }
  .learning-material p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #555;
  }
  
  .learning-material ul {
    margin-left: 20px;
    list-style-type: disc; /* Adds bullet points */
  }
  
  .learning-material li {
    margin-bottom: 12px; /* Adds spacing between list items */
    font-size: 18px;
  }
  
  .iframe-container {
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    height: calc(100vh - 60px);
  }
  
  iframe {
    position: absolute;
    top: -38px;
    left: 0;
    width: 100%;
    height: 100vh;
    border: none;
  }
  </style>
  