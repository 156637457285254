<template>
    <div class="main-page-wrapper p0 overflow-hidden">
      <Header />
  
      <!-- Main content area with sidebar -->
      <div class="service-details-one pt-200 md-pt-150">
          <div class="row mx-4">
            <!-- Sidebar -->
            <div class="col-xl-2 col-lg-2 col-md-3 order-lg-first">
              <div class="sidebar md-mt-100">
                <div class="category-list">
                  <h4 class="font-gordita dashboard_menue">Menu</h4>
                  <ul>
                    <li @click="navigateToDashboard('courses')">
                      <img src="../../assets/images/icon/course.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'courses' }">Products</a>
                    </li>
                    <li @click="navigateToDashboard('learning')">
                      <img src="../../assets/images/icon/robot.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'learning' }">Learning</a>
                    </li>
                    <li @click="navigateToDashboard('simulator')">
                      <img src="../../assets/images/icon/simulation.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'simulator' }">Online Simulator</a>
                    </li>
                    <li @click="navigateToDashboard('orbital')">
                      <img src="../../assets/images/icon/unlock.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'orbital' }">Orbital</a>
                    </li>
                    <li @click="navigateToDashboard('community')">
                      <img src="../../assets/images/icon/group.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'community' }">Community</a>
                    </li>
                    <li @click="navigateToDashboard('profile')">
                      <img src="../../assets/images/icon/user.png" style="display: inline;">
                      <a href="#" :class="{ 'active': activeContent === 'profile' }">Profile</a>
                    </li>
                  </ul>
                </div> <!-- /.category-list -->
                <div class="sidenote">
                  <p>Empowering Your Robotics Journey - Code, Connect, Create!</p>
                  <span>- Roboquark</span>
                </div>
              </div> <!-- /.sidebar -->
            </div>
  
            <!-- Main content -->
            <div class="col-xl-10 col-lg-10 order-lg-last">
              <div class="project-container">
                <h2 class="font-rubik">Arduino Button-Controlled LCD Text Display</h2>
                <div class="learning-material">
                  <h3 class="font-rubik pt-50">Circuit Overview</h3>
                  <p>
                    This project uses an Arduino, a push button, and a colorful LCD to display interactive text. Initially, the LCD shows "HELLO" in green. When the button is pressed, it changes to "THANK YOU" in red, toggling between the messages with each press.
                  </p>
                  <h4>Components and Pin Connections</h4>
                  <ul>
                    <li>Arduino Uno</li>
                    <li>LCD Display:</li>
                    <ul>
                      <li>VCC → Arduino 5V</li>
                      <li>GND → Arduino GND</li>
                      <li>CS → Arduino Pin 10</li>
                      <li>DC → Arduino Pin 9</li>
                      <li>RST → Arduino Pin 8</li>
                      <li>SCK → Arduino Pin 13</li>
                      <li>MOSI → Arduino Pin 11</li>
                    </ul>
                    <li>Push Button:</li>
                    <ul>
                      <li>One Terminal → Arduino Pin 7</li>
                      <li>Other Terminal → Arduino GND</li>
                    </ul>
                  </ul>
                  <h4>How the Circuit Works</h4>
                  <h5>Initial Display:</h5>
                  <ul>
                    <li>The LCD shows "HELLO" in green at startup.</li>
                  </ul>
                  <h5>Button Press:</h5>
                  <ul>
                    <li>The Arduino detects the button press and switches the display between "HELLO" and "THANK YOU".</li>
                  </ul>
                  <h5>Persistent Display:</h5>
                  <ul>
                    <li>The text stays until the button is pressed again.</li>
                  </ul>
                  <h4>Example Applications</h4>
                  <ul>
                    <li><strong>Interactive Robots</strong>: A robot can display "THANK YOU" on its LCD when touched.</li>
                    <li><strong>Simple User Interfaces</strong>: Provides responsive feedback, allowing devices to react to user input.</li>
                  </ul>
                  <h3>Try this project on a simulator to see it in action!</h3>
                </div>
                <div class="iframe-container">
                  <iframe src="https://wokwi.com/projects/413262891564702721" frameborder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
      </div>
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../common/Headers/dashboardheader.vue";
  import Footer from "../common/Footers/FooterSeven.vue";
  
  export default {
    name: 'ButtonControlledLCD',
    components: { Header, Footer },
    data() {
      return {
        activeContent: 'courses' // Default section, if needed
      };
    },
    methods: {
      navigateToDashboard(section) {
        // Navigate to the dashboard and pass the section as a query parameter
        this.$router.push({ path: '/dashboard', query: { content: section } });
      }
    }
  };
  </script>
  
  <style scoped>
  .project-container {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-family: 'Roboto', sans-serif;
  }
  
  .learning-material {
    margin-bottom: 20px;
    line-height: 1.8;
    font-size: 16px;
    color: #333;
  }
  
  .learning-material h3 {
    margin-top: 15px;
    color: #0056b3; /* Blue color for headers */
    font-weight: 600;
  }
  .learning-material h4 {
    margin-top: 20px;
    margin-bottom: 15px;
    color: #00b3b3; /* Aqua color for headers */
    font-weight: 400;
  }
  
  .learning-material h5 {
    margin-top: 20px;
    margin-bottom: 15px;
    color: #090a0a; /* Dark color for headers */
    font-weight: 400;
  }
  .learning-material p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #555;
  }
  
  .learning-material ul {
    margin-left: 20px;
    list-style-type: disc; /* Adds bullet points */
  }
  
  .learning-material li {
    margin-bottom: 12px; /* Adds spacing between list items */
    font-size: 18px;
  }
  
  .iframe-container {
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    height: calc(100vh - 60px);
  }
  
  iframe {
    position: absolute;
    top: -38px;
    left: 0;
    width: 100%;
    height: 100vh;
    border: none;
  }
  </style>
  