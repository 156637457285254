<template>
  <div class="hero-banner-eleven lg-container">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6 heros">
          <div class="hero-text-wrapper">
            <h1>
              {{ homePages.header1 }} <br> {{ homePages.header2 }}

            </h1>
            <p class="hero-sub-heading"> {{ homePages.subHeading }} </p>
            <div class="d-sm-flex align-items-center button-group cta">
            <a href="/register" class=" start-button d-flex align-items-center">Get Start</a>
            <!-- <a href="#" class=" how-button d-flex align-items-center">How it works</a> -->
        </div>
            <p class="term-text">No credit card required</p>
          </div>
          <!-- /.hero-text-wrapper -->
        </div>
        <!-- End .col -->

        <div class="illustration-container">
          <img src="../../assets/images/assets/robo.png" alt="" />
        </div>
      </div>
      <!-- End .row -->
    </div>
    <!-- End .container -->

    <div class="partner-slider-two mt-150 md-mt-20">
      
    </div>
    <!-- /.partner-slider-two -->
  </div>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
  // -------------Strapi-----------
  import axios from 'axios';
  // -------------Strapi-----------

export default {
  name: "HeroBanner",
  data() {
    return {
      homePages: {},
    };
  },
  components: {
    Carousel,
    Slide,
  },
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },

  // -------------Strapi-----------
  async mounted() {
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URL+'api/home-pages');
      response.data.data.forEach(item => {
        this.homePages[item.attributes.title]=item.attributes.content;
      });
      // console.log(this.homePages);
      console.log(process.env.VUE_APP_STRAPI_URL+'api/home-pages');

      } catch (error) {
      console.error(error);
    }
  },
    // -------------Strapi-----------

};
</script>
