<template>
  <div class="blog-sidebar-one">
    <div class="sidebar-search-form  mb-85 sm-mb-60">
      <form @submit.prevent="onSubmit">
        <input type="text" placeholder="Search">
        <button>
          <img v-if="!blog_v3" src="../../assets/images/icon/50.svg" alt="">
          <img v-if="blog_v3" src="../../assets/images/icon/51.svg" alt="">
        </button>
      </form>
    </div> <!-- /.sidebar-search-form -->
    <div class="sidebar-categories mb-85 sm-mb-60">
      <h4 class="sidebar-title">Categories</h4>
      <ul >
        <li v-for="category in categories" :key="category">
          {{ category.name }} <span>({{ category.count }})</span>      </li>
     
      </ul>
    </div> <!-- /.sidebar-categories -->
    <div class="sidebar-recent-news mb-85 sm-mb-60">
      <h4 class="sidebar-title">Recent News</h4>
      <ul>
        <li v-for="(post, index) in recentPosts" :key="index">
      <router-link :to="`/blog-details/?id=${blogIdMap[index]}`">
        <span class="title">{{ post.title }}</span>
        <span class="date">{{ new Date(post.publish).toLocaleDateString() }}</span>
      </router-link>
    </li>
       
      </ul>
    </div> <!-- /.sidebar-recent-news -->
    <!-- <div class="sidebar-keyword">
      <h4 class="sidebar-title">Keywords</h4>
      <ul class="cleafix">
        <li><a href="#">Ideas</a></li>
        <li><a href="#">Education</a></li>
        <li><a href="#">Design</a></li>
        <li><a href="#">Development</a></li>
        <li><a href="#">Branding</a></li>
      </ul>
    </div> /.sidebar-keyword -->
  </div>
</template>



<script>
import axios from 'axios';

export default {
  name: 'BlogSidebar',
  props: {
    blog_v3: Boolean,
  },
  data() {
    return {
      blogPosts: [],
      categories: [],
      recentPosts:[],
    };
  },
  async mounted() {
    await this.fetchBlogsWithCategories();
    await this.fetchRecentPosts();

  },
  methods: {
    async fetchRecentPosts() {
    try {
      console.log(process.env.VUE_APP_STRAPI_URL + 'api/blogs?sort=publishedAt:desc&pagination[limit]=3');

      // Fetch the blog posts sorted by creation date in descending order
      const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/blogs?sort=publishedAt:desc&pagination[limit]=3');
      this.items = response.data.data;

      console.log(response);
      this.recentPosts = response.data.data.map(item => item.attributes);
      console.log("recentposts"+this.recentPosts);
      this.blogIdMap = this.items.reduce((map, item, index) => {
        map[index] = item.id;
        return map;
      }, {});
      console.log("id "+this.blogIdMap);

    } catch (error) {
      console.error('Error fetching recent blog posts:', error);
    }
  },
    async fetchBlogsWithCategories() {
      try {
        const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/blogs?populate=category');
        console.log("respons: "+response);
        this.blogPosts = response.data.data;
        console.log("respons: "+this.blogPosts);

        this.extractCategories();
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    },

    //extract all categories across blog collection in strapi and count each category blogs 
    extractCategories() {
    // Object to hold the category names and their counts
    const categoryCounts = {};

    this.blogPosts.forEach(post => {
      // Ensure the post has the category component and it's an array
      if (Array.isArray(post.attributes.category) && post.attributes.category.length) {
        // Iterate over each category in the post's category array
        post.attributes.category.forEach(categoryComponent => {
          // Check if the category name exists
          const categoryName = categoryComponent.name;
          if (categoryName) {
            // If the category has already been added to the object, increment its count
            if (categoryCounts[categoryName]) {
              categoryCounts[categoryName]++;
            } else {
              // Otherwise, initialize it with a count of 1
              categoryCounts[categoryName] = 1;
            }
          }
        });
      }
    });

    // Convert the object to an array of objects with name and count properties
    this.categories = Object.entries(categoryCounts).map(([name, count]) => ({
      name,
      count
    }));

    console.log(this.categories);
  },



    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>
