<template>
  <div class="form-style-light">
    <form @submit.prevent="onSubmit" id="contact-form" data-bs-toggle="validator">
      <div class="messages"></div>
      <div class="row controls">
        <div class="col-md-6">
          <div class="input-group-meta form-group mb-35">
            <label>User Name</label>
            <input type="text" v-model="user.username" placeholder="Michel" name="Fname" required="required"
              data-error="Name is required.">
            <span class="placeholder_icon valid-sign">
              <img src="../../assets/images/icon/18.svg" alt="">
            </span>
            <div class="help-block with-errors"></div>
          </div>
        </div>

        <div class="col-12">
          <div class="input-group-meta form-group mb-35">
            <label>Your Email</label>
            <input type="email" v-model="user.email" placeholder="gobapubo@jogi.net" name="email" required="required"
              data-error="Valid email is required.">
            <span class="placeholder_icon valid-sign">
              <img src="../../assets/images/icon/18.svg" alt="">
            </span>
            <div class="help-block with-errors"></div>
          </div>
        </div>
     
        <div class="col-12"><button type="submit" class="theme-btn-one btn-lg">Update</button></div>
      </div>
    </form>
  </div> <!-- /.form-style-light -->
</template>
<script>
//strapi
import axios from 'axios';

export default {
  name: 'Profile',
  data() {
    return {
      hidePassword: false,
      user: {
        username: '',
        email: '',
        password: ''
      }
    }
  },

  methods: {
    handleHidePassword() {
      this.hidePassword = !this.hidePassword
    },

    async fetchUserData() {
      try {
        const token = localStorage.getItem('jwt'); // Assuming JWT token is stored in localStorage

        if (!token) {
          console.error('No token found');
          // Handle the case where there is no token
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/users/me', config);


        this.user.username = response.data.username;
        this.user.email = response.data.email;
        // Don't fetch the password
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error
      }
    },
    async onSubmit() {
      try {
        const token = localStorage.getItem('jwt');

        console.log(token);
        if (!token) {
          console.error('No token found');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const updateData = {
          data: {
            username: this.user.username,
            email: this.user.email
            // Password should not be included unless it's being changed
          }
        };
        console.log(process.env.VUE_APP_STRAPI_URL + 'api/users/me');
        // Using PUT instead of PATCH
        await axios.put(process.env.VUE_APP_STRAPI_URL + 'api/users/me', updateData, config);

        alert('Profile updated successfully');
      } catch (error) {
        console.error('Error updating profile:', error.response || error);
      }
    },
  mounted() {
    this.fetchUserData();
    // if user was not authenticated befor redirect to login
    if (!this.isAuthenticated()) {
      this.$router.push('/login'); // Redirect to login page
    }
  }
},
computed: {
    isAuthenticated() {
      const token = localStorage.getItem('jwt');
      return !!token; // Returns true if token exists, false otherwise
    }
  },

}
</script>