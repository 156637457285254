

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="row  modal-container">

        <!-- Modal Header with Image, Heading, and Paragraph -->
        <div class="  col-6 modal-left modal-header">
          <button class="close-button" @click="$emit('close')">X</button>

          <img src="../../assets/images/assets/small-product.png" alt="Modal Image" class="modal-image" />

          <!-- <h4>Beginner-Level Educational Robot: A Fun and Interactive Way to Learn Robotics and Coding!</h4> -->
          <h4>Beginner-Level Educational Robot</h4>

          <p>This cutting-edge robot is the perfect tool for anyone looking to get ahead in the world of robotics. Don't wait, secure your order today and start exploring all the exciting possibilities this robot offers!</p>
        </div>

        <!-- Modal Body with Form -->
        <div class="col-6  modal-right modal-body">
          <form @submit.prevent="submitForm" class="form">
            <input type="text" v-model="name" placeholder="Name" class="modal-input" />
            <input type="email" v-model="email" placeholder="Email" class="modal-input" />
            <input type="text" v-model="country" placeholder="Country" class="modal-input" />
            <input type="tel" v-model="phonenumber" placeholder="Phone Number" class="modal-input" />
            <label class="modal-checkbox">
              <input type="checkbox" v-model="agreeToTerms"> Notify me when it's available.
            </label>
            <button type="submit" class="modal-submit-button">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>
  
<script setup>
import { defineProps, reactive, toRefs } from 'vue';
import axios from 'axios';

const props = defineProps({
  show: Boolean
});
const emit = defineEmits(['close']);


// Create a reactive object for your form data
const formData = reactive({
  name: '',
  email: '',
  country: '',
  phonenumber: '',
  agreeToTerms: false
});

// Method to submit form data
const submitForm = async () => {
  // Check if the terms are agreed to
  if (!agreeToTerms.value) {
    alert('You must agree to the terms.');
    return;
  }

  // Prepare the data to be sent
  const dataToSend = {
    fullname: name.value,
    email: email.value,
    country: country.value,
    phonenumber: phonenumber.value
  };

  try {
    const response = await axios.post(process.env.VUE_APP_STRAPI_URL + 'api/preorders', {
      data: {
        fullname: formData.fullname,
        email: formData.email,
        country: formData.country,
        phonenumber: formData.phonenumber,
      }
    });
    console.log(response.data);
    // If successful, you can close the modal and clear the form
    props.show = false; // or use an emit if you need to notify the parent component
    // Reset the form fields here if necessary
    emit('close');
    // You can also reset the form here if necessary
    formData.fullname = '';
    formData.email = '';
    formData.country = '';
    formData.phonenumber = '';
    formData.agreeToTerms = false;
  } catch (error) {
    console.error('There was an error submitting the form:', error);
  }
};


// Expose formData to the template
const { name, email, country, phonenumber, agreeToTerms } = toRefs(formData);
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 1%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  display: flex;
  width: 70%;
  /* Adjust as necessary */
  min-width: 700px;
  /* For responsiveness */
  height: 500px;
  /* Or whatever fits your content */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  border-radius: 10px;
  overflow: hidden;
  /* Clip the children to the border radius */
  background-color: #1e1c1c;
  /* Dark theme background */


}

.close-button {

  top: 10px;
  background: none;
  border: none;
  color: #fff;
  /* White color for the 'X' */
  font-size: 1.5rem;
  /* Larger size for the 'X' */
  cursor: pointer;
}

.close-button:hover {
  color: #ccc;
  /* Lighter color on hover */
}

.modal-left {
  flex: 1;

}
.modal-left p{
  padding-left:3% ;
  align-content: center;

}
.modal-right {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  padding: 3%;
  margin: 3%;
}

.modal-header {
  flex-basis: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  /* Ensure the image is responsive and fits well */
  height: auto;
  margin-bottom: 10px;
}

.modal-header h4 {
  color: #f1f1f1;
  margin: 0;
}

.modal-header p {
  color: #f1f1f1;
  font-size: 14px;
  margin: 10px 0 20px;
}

.modal-body {
  flex-basis: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-checkbox {
  margin-bottom: 10px;
  user-select: none;
  /* Prevent text selection on double click */
}

.modal-checkbox input {
  margin-right: 5px;
}

.modal-submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-submit-button:hover {
  background-color: #0056b3;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(1.1);
}

@media (max-width: 768px) {

  /* Adjust breakpoint as needed */
  .modal-container {
    flex-direction: column;
    /* Stack vertically on small screens */
    width: 90%;
    /* Adjust width for small screens */
    height: auto;
    /* Let the height be determined by content */
    overflow: visible;
    /* Since we're not hiding overflow anymore */
  }

  .modal-left,
  .modal-right {
    width: 100%;
    /* Take full width on small screens */
    flex: none;
    /* Do not grow or shrink */
  }

  .modal-content h4 {
    font-size: 1.5em;
    /* Adjust heading size for small screens */
  }

  .modal-content p {
    font-size: 0.9em;
    /* Adjust paragraph size for small screens */
  }


}</style>
  
  