<template>
  <div class="main-page-wrapper overflow-hidden">
    <Header />
    <Hero/>
    <Blog/>
    <ShortBanner :ser_page="true" />
    <Footer />
  </div>
</template>

<script>

import Header from "../common/Headers/HeaderFive.vue";
import Blog from "./blog-area.vue";
import ShortBanner from "./ShortBanner.vue";
import Footer from "../common/Footers/FooterSeven.vue";
import Hero from "./hero-area.vue";


export default {
  name: "Blog_Main",
  components: { Header, Hero,Blog, ShortBanner, Footer },
};
</script>