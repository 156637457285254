<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <HeaderLoggedIn  v-if="isAuthenticated" />
    <HeaderVisitor v-else />
    <div class="error-page d-lg-flex align-items-center">
      <Course />
   
    </div>
  </div>
</template>

<script>
import Course from "../components/courses/coursedetails.vue";
import HeaderLoggedIn from "../components/common/Headers/dashboardheader.vue";
import HeaderVisitor from "../components/common/Headers/HeaderFive.vue";

export default {
  name: 'CoursePage',
  components: { Course, HeaderLoggedIn, HeaderVisitor },
  computed: {
    isAuthenticated() {
      const token = localStorage.getItem('jwt');
      return !!token; // Returns true if token exists, false otherwise
    }
  },
  methods:{
    mounted() {
    // if user was not authenticated befor redirect to login
  if (!this.isAuthenticated()) {
    this.$router.push('/login'); // Redirect to login page
  }
},
  }
}

</script>