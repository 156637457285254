<template>
    <FaqDetailsMain/>
  </template>
  
  <script>
  import FaqDetailsMain from '../components/dashboard/faq-details.vue';
  
  export default {
    name:'FaqDetails',
    components:{FaqDetailsMain}
  }
  </script>