<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <Header />
    <div class="error-page d-lg-flex align-items-center">
      <Learning />
   
    </div>
  </div>
</template>

<script>
import Learning from "../components/dashboard/doc-area.vue";
import Header from "../components/common/Headers/dashboardheader.vue";

export default {
  name: 'LearningPage',
  components: { Learning ,Header},
 
  
 methods: {
  isAuthenticated() {
      const token = localStorage.getItem('jwt');
      return !!token; // Returns true if token exists, false otherwise
    }
 },
 mounted() {
    // if user was not authenticated befor redirect to login
    if (!this.isAuthenticated()) {
      this.$router.push('/login'); // Redirect to login page
    }
  },
}
</script>