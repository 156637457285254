<template>
    <div class="">
      <FancyHero/>
      <FancyTextBlock2/>
    </div>
  </template>
  
  <script>
  import FancyHero from './fancy-hero.vue';
  import FancyTextBlock2 from './fancy-text-block-2.vue';
  
  export default {
    name:'SolutionManagementMain',
    components: {  FancyHero, FancyTextBlock2 },
  }
  </script>