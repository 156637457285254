<template>
  <div class="blog-page-white-bg blog-v3">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="feature-blog-three">

            <div  v-for="(blogitem, index) in blogitems" :key="blogitem.id" class="post-meta">
              <img :src="require(`@/assets/images/blog/${blogitem.image}`)" alt="" class="image-meta">

              <div class="post">
                <div class="date">{{blogitem.publish}}</div>
                <h3>
                  <router-link :to="`/blog-details/?id=${blogIdMap[index]}`" class="title">{{blogitem.title}}</router-link>
                </h3>
                <p>{{blogitem.summary}}</p>
                <router-link :to="`/blog-details/?id=${blogIdMap[index]}`" class="read-more">
                  <span>Continue Reading</span>
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </router-link>
              </div>
            </div> <!-- /.post-meta -->
  
          </div>

          <!-- <div class="page-pagination-one pt-15">
            <ul class="d-flex align-items-center">
              <li><a href="#"><i class="fa fa-angle-left" aria-hidden="true"></i></a></li>
              <li><a href="#" class="active">1</a></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li> &nbsp; ... &nbsp;</li>
              <li><a href="#">13</a></li>
              <li><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
            </ul>
          </div>  -->
          <!-- /.page-pagination-one -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- BlogSidebar start -->
          <BlogSidebar :blog_v3="true" />
          <!-- BlogSidebar end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogSidebar from '../common/blog-sidebar.vue';

// -------------Strapi-----------
import axios from 'axios';
// -------------Strapi-----------

export default {
  name: 'BlogArea',
  components: { BlogSidebar },
  data() {
    return {
      blogitems: [],
      blogIdMap: [],
    };
  },

  async mounted() {
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/blogs');
      this.items = response.data.data;
      console.log(this.items);
      this.blogitems = response.data.data.map(item => item.attributes);

      //  using JavaScript's Array.prototype.reduce method to transform an array (this.items) 
      //into a single object (this.courseIdMap):
      this.blogIdMap = this.items.reduce((map, item, index) => {
        map[index] = item.id;
        return map;
      }, {});

      console.log("id "+this.blogIdMap);
      // console.log(response.data.data);
      console.log(this.blogitems);

    } catch (error) {
      console.error(error);
    }
  },
}
</script>