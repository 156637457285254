<template>
  <div class="blog-page-bg">
    <div class="shapes shape-one"></div>
    <div class="shapes shape-two"></div>
    <div class="shapes shape-three"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-11 feature-blog-one width-lg blog-details-post-v1" v-if="blogdata">
          <div class="post-meta">
            <img v-if="blogdata.image" :src="require(`@/assets/images/blog/${blogdata.image}`)" alt="" class="image-meta">
            <div class="tag">{{ new Date(blogdata.publish).toLocaleDateString() }}</div>
            <h3 class="title">{{ blogdata.title }}</h3>
            <!-- <p>{{ blogdata.content }}</p> -->
            <div class="blog-content" v-if="blogdata && Array.isArray(blogdata.content)" v-html="renderContent(blogdata.content)"></div>

            <div class="d-sm-flex align-items-center justify-content-between share-area">
              <!-- <ul class="tag-feature d-flex">
                <li>Tag: &nbsp;</li>
                <li><a href="#">business,</a></li>
                <li><a href="#">makreting,</a></li>
                <li><a href="#">tips</a></li>
              </ul>
              <ul class="share-option d-flex align-items-center">
                <li>Share</li>
                <li><a href="#" style="background: #F6616F;">
                    <i class="fa fa-google-plus" aria-hidden="true"></i></a>
                </li>
                <li><a href="#" style="background: #41CFED;">
                    <i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#" style="background: #588DE7;">
                    <i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              </ul> -->
            </div>
          </div> <!-- /.post-meta -->


        </div>
        <div class="col-lg-4 col-md-6">
          <!-- blog side bar start -->
          <!-- blog side bar end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'DetailsArea',
  data() {
    return {
      blogdata: [],

    };
  },
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
    renderContent(content) {
      if (!Array.isArray(content)) return ''; // Ensure content is an array

      let htmlContent = '';

      content.forEach(block => {
        switch (block.type) {
          case 'paragraph':
            htmlContent += `<p>${block.children.map(child => child.text).join('')}</p>`;
            break;
          case 'heading':
            htmlContent += `<h${block.level}>${block.children[0].text}</h${block.level}>`;
            break;
          case 'list':
            // Start a list (unordered or ordered based on format)
            const listTag = block.format === 'unordered' ? 'ul' : 'ol';
            htmlContent += `<${listTag}>${block.children.map(listItem => {
              // Process each list item
              return `<li>${listItem.children.map(child => child.bold ? `<strong>${child.text}</strong>` : child.text).join('')}</li>`;
            }).join('')}</${listTag}>`;
            break;
          case 'image':
            // Handle images
            const imageUrl = block.image?.url; // Get the image URL from the block

            if (imageUrl) {
              htmlContent += `<img src="${imageUrl}" alt="${block.image.alternativeText}" />`;
            }
            break;
        }
      });

      return htmlContent;
    }



  },

  async mounted() {
    const blogId = this.$route.query.id;

    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/blogs');
      console.log(response);
      // Assuming that you want to display the lessons of the first course
      //   const firstCourse = courseId; // Get the first course from the array
      const blogs = response.data.data;
      const firstblog = blogs.find(blog => blog.id.toString() === blogId);
      console.log("first" + this.firstblog);

      if (firstblog) {
        this.blogdata = firstblog.attributes; // Assign the data property of the first course to coursedataata
        if (!Array.isArray(this.blogdata.content)) {
          this.blogdata.content = [];

        }
        console.log("content" + JSON.stringify(this.blogdata.content, null, 2));
      }
      console.log(this.blogdata);

    } catch (error) {
      console.error('Error fetching course data:', error);
    }
  },
}
</script>