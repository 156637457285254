<template>
    <Blog_Main/>
  </template>
  
  <script>
  import Blog_Main from '../components/blog';
  
  export default {
    name:'Blog',
    components:{Blog_Main}
  }
  </script>