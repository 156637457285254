<template>
  <div class="main-page-wrapper overflow-hidden">
    <Header/>
    <FancyHero/>
    <DetailsArea/>
    <Footer/>
  </div>
</template>

<script>
import Header from '../common/Headers/HeaderFive.vue';
import FancyHero from './fancy-hero.vue';
import DetailsArea from './details-area.vue';
import Footer from '../common/Footers/FooterSeven.vue';

export default {
  name:'BlogDetailsMain',
  components: { Header, FancyHero, DetailsArea, Footer },
}
</script>