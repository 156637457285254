<template>
  <div class="fancy-hero-two">
    <div class="bg-wrapper">
      <div class="container">
        <div class="page-title">Blog</div>
        <div class="row">
          <div class="col-xl-8 col-lg-10 m-auto">
            <h1 class="heading md-p0">Explore the Latest in Robotics</h1>
            <h4 class="sub-heading md-p0"> Discover New Insights and Categories</h4>
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'HeroArea'
}
</script>