<template>
  <div class="main-page-wrapper overflow-hidden">
    
    <!-- faq details area start -->
    <div class="faqs-inner-page">
      <img src="../../assets/images/shape/66.svg" alt="" class="shapes shape-one">
      <div class="shapes shape-two"></div>
      <div class="shapes shape-three"></div>
      <div class="shapes shape-four"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-11 m-auto">
            <div class="all-faqs">
              <div class="faqs-all-qus md-m0">
                <div v-if="faq"  class="article-preview">
                  <div class="d-flex">
                    <div>
                      <h3 class="font-rubik">{{faq.question}}</h3>
                      <div class="avatar-info">
                        Written by <span>{{faq.user}}</span>
                      </div>
                    </div>
                  </div>


                  <div class="article-details">
                    <p>{{faq.answer}}</p>
                 
                   

                    <div class="reaction-wrapper">
                      <h4>Did this answer your question?</h4>
                      <div class="d-flex align-items-center justify-content-center">
                        <button><img src="../../assets/images/icon/happy.svg" alt=""></button>
                        <button><img src="../../assets/images/icon/sad.svg" alt=""></button>
                        <button><img src="../../assets/images/icon/surprised.svg" alt=""></button>
                      </div>
                    </div> <!-- /.reaction-wrapper -->
                  </div> <!-- /.article-details -->
                </div> <!-- /.article-preview -->
              </div> <!-- /.faqs-all-qus -->
            </div> <!-- /.all-faqs -->
          </div>
        </div>
      </div>
    </div>
    <!-- faq details area end -->
  </div>
</template>

<script>
    import axios from 'axios';
    
    export default {
      name: 'FaqDetails',
      data() {
        return {
          faq: null
        };
      },
      async created() {
        try {
          const faqId = this.$route.params.id;

          console.log(faqId);

          console.log(`${process.env.VUE_APP_STRAPI_URL}api/faqs/${faqId}`);
          const response = await axios.get(`${process.env.VUE_APP_STRAPI_URL}api/faqs/${faqId}`);
          this.faq = {
  id: response.data.data.id,
  user: response.data.data.attributes.question.user,
  question: response.data.data.attributes.question.question,
  answer: response.data.data.attributes.answer.answer,
};
 
            } catch (error) {
          console.error('Error fetching FAQ:', error);
        }
      }
    }
    </script>
    