
<template>

    <div class="product-details-one lg-container pt-180 lg-pt-150">

        <div class="container">
            <div class="row">

                <div class="col-lg-7 order-lg-3">
                    <div class="product-info pl-xl-5 md-mt-50">

                        <h3 class="product-name">{{ coursedata.title }}</h3>
                        <ul class="style-none d-flex align-items-center rating">
                            <li><i class="fa fa-star" aria-hidden="true"></i></li>
                            <li><i class="fa fa-star" aria-hidden="true"></i></li>
                            <li><i class="fa fa-star" aria-hidden="true"></i></li>
                            <li><i class="fa fa-star" aria-hidden="true"></i></li>
                            <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                            <li><a href="#">(2 Customer Reviews)</a></li>
                        </ul>
                        <p class="description-text">{{ coursedata.description }}</p>
                        <ul class="product-feature style-none">
                            <li v-for="requirement in coursedata.requirements" :key="requirement">{{ requirement }}</li>
                        </ul>

                        <div class="course-features">{{ coursedata.level }}</div>
                        <div class="course-features">{{ coursedata.schedule }}</div>

                        <div class="courseprice">Price:  Є{{ courseprice }}</div>
                    </div> <!-- /.product-info -->
                </div>
                <div class="col-lg-6 order-lg-3 d-none d-md-block">
                    <box />
                </div>
            </div>

            <div class="col-8 product-review-tab mb-500 mt-70 lg-mt-100">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <button @click="changeTab('description')" :class="{ active: activeTab === 'description' }"
                            class="nav-linkp " data-toggle="tab" data-target="#item1" type="button" role="tab"
                            aria-selected="true">Description</button>
                    </li>
                    <li class="nav-item">
                        <button @click="changeTab('lesson')" :class="{ active: activeTab === 'lesson' }" class="nav-linkp"
                            data-toggle="tab" data-target="#item2" type="button" role="tab" aria-selected="false">Lessons
                            Info</button>
                    </li>
                    <!-- <li class="nav-item">
                        <button @click="changeTab('course-instructors')"
                            :class="{ active: activeTab === 'course-instructors' }" class="nav-linkp" data-toggle="tab"
                            data-target="#item3" type="button" role="tab" aria-selected="false">Course Instructors</button>
                    </li> -->
                </ul>
                <div class="tab-content  mt-40 lg-mt-20">
                    <div v-if="activeTab === 'description'" class="tab-pane fade active show" id="item1" role="tabpanel">
                        <h5>Specifications:</h5>
                        <div class="col-10">
                            <p>{{ coursedata.specification }}</p>
                        </div>
                        <!-- <ul class="style-none product-feature">
                            <li>Lorem ipsum best lightweight bra cool rejection avoid text</li>
                            <li>Lightweight bras cool rejection quickly quis.</li>
                            <li>We quickly learn to fear and automatically avoid potentially</li>
                        </ul> -->


                    </div>
                    <div v-else-if="activeTab === 'lesson'" class="tab-pane fade active show" id="item2" role="tabpanel">
                        <div class="row gx-5">
                            <lesson />

                        </div>
                    </div>
                    <div v-else-if="activeTab === 'course-instructors'" class="tab-pane fade active show" id="item3"
                        role="tabpanel">
                        <div class="user-comment-area">
                            <div class="single-comment d-flex align-items-top">
                                <img src="" alt="" class="user-img">
                                <div class="user-comment-data">
                                    <h6 class="name">Rashed ka.</h6>
                                    <ul class="style-none d-flex rating">
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                                    </ul>
                                    <p>One touch of a red-hot stove is usually all we need to avoid that kind of discomfort
                                        in future. The same true we experience the emotional sensation.</p>
                                </div> <!-- /.user-comment-data -->
                            </div> <!-- /.single-comment -->
                            <div class="single-comment d-flex align-items-top">
                                <img src="" alt="" class="user-img">
                                <div class="user-comment-data">
                                    <h6 class="name">Zubayer hasan</h6>
                                    <ul class="style-none d-flex rating">
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                                    </ul>
                                    <p>One touch of a red-hot stove is usually all we need to avoid that kind of discomfort
                                        in future. The same true we experience the emotional sensation.</p>
                                </div> <!-- /.user-comment-data -->
                            </div> <!-- /.single-comment -->
                        </div>
                    </div>
                </div>
            </div> <!-- /.product-review-tab -->


        </div>
    </div>
</template>
<script>
import box from "./box.vue";
import lesson from "./lesson.vue";
import axios from 'axios';

export default {
    name: 'coursedetails',
    components: { box, lesson },
    data() {
        return {
            activeTab: 'description', // Set the default active tab
            coursedata: [],
            courseprice: [],

        };
    },
    methods: {
        changeTab(tabName) {
            this.activeTab = tabName;
            console.log('Active Tab:', this.activeTab);

        },
    },

    async mounted() {
        const courseId = this.$route.query.id;

        try {
            const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/courses');
            console.log(response);
            // Assuming that you want to display the lessons of the first course
            //   const firstCourse = courseId; // Get the first course from the array
            const courses = response.data.data;
            const firstCourse = courses.find(course => course.id.toString() === courseId);

            if (firstCourse) {
                this.coursedata = firstCourse.attributes.data; // Assign the data property of the first course to coursedataata
                this.courseprice = firstCourse.attributes.price;
            }
            console.log(this.coursedataata);

        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    },
};
</script>