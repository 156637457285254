<template>
  <div class="pricing-section-one mb-150 md-mb-80" id="product">
    <div class="fancy-hero-one">
      <div class="container">
        <div class="row">
          <div class="col-xl-10 col-lg-11 m-auto">
            <h2 class="font-rubik mb-70 mt-70">Choose a product match your skills</h2>
          </div>

        </div>

      </div>
      <div class="bubble-one"></div>
      <div class="bubble-two"></div>
      <div class="bubble-three"></div>
      <div class="bubble-four"></div>
      <div class="bubble-five"></div>
      <div class="bubble-six"></div>
    </div> <!-- /.fancy-hero-one -->

    <div class="pricing-table-area">
      <img src="../../assets/images/shape/62.svg" alt="" class="shapes shape-one">
      <img src="../../assets/images/shape/63.svg" alt="" class="shapes shape-two">
      <div class="container">
        <div class="tab-content">

          <div class="row justify-content-center">
            <div v-for="(courseitem, index) in courseitems" :key="courseitem.id" class="col-lg-4 col-md-6">
              <div class="pr-table-wrapper">
                <div class="pack-name">{{ courseitem.title }}</div>
                <div class="pricepart">
                  <!-- Conditionally render the original price with a line through it -->
                  <div v-if="courseitem.price === 50" class="price  line-through font-rubik">
                    Є{{ courseitem.price }}
                  </div>
                  <!-- Conditionally render the half price in a different color -->
                  <div v-if="courseitem.price === 50" class="price half-price font-rubik">
                    Є{{ courseitem.price / 2 }}
                  </div>
                  <!-- Render the normal price when it is not equal to 50 -->
                  <div v-else class="price font-rubik">
                    Є{{ courseitem.price }}
                  </div>
                </div> <!-- <img :src="require(`@/assets/images/blog/${courseitem.image}`)" alt="" class="icon"> -->
                <ul class="pr-feature">
                  <li>
                    Robotic Tutorials: {{ courseitem.data.robotictutorials }}
                  </li>
                  <li>
                    Expert Community: {{ courseitem.data.expertcommunity }}
                  </li>
                  <li>
                    Online Simulator: {{ courseitem.data.onlinesimulator }}
                  </li>
                  <li>
                    Hardware Package : {{ courseitem.data.hardwarepackage }}
                  </li>
                </ul>
                <router-link :to="`/coursedetails?id=${courseIdMap[index]}`">

                  <a v-if="courseitem.title !== 'Roboquark Hardware package'" class="theme-btn-four">Enroll</a>

                </router-link>
                <a v-if="courseitem.title == 'Roboquark Hardware package'" class="theme-btn-four" id="show-modal"
                  @click="showModal = true">Pre-Order</a>

                <Teleport to="body">
                  <!-- use the modal component, pass in the prop -->
                  <Modal :show="showModal" @close="showModal = false">
                   
                  </Modal>
                </Teleport>
              </div> <!-- /.pr-table-wrapper -->
            </div>

          </div>

        </div>
      </div>
    </div> <!-- /.pricing-table-area -->
  </div>

  <!-- Popup Modal -->
</template>
  


<script>
// -------------Strapi-----------
import axios from 'axios';
// -------------Strapi-----------
import Modal from '../robo/modal.vue';
import { ref } from 'vue';

const showModal = ref(false);
export default {
  name: 'Coursses',
  // -------------Strapi-----------
  components: {
    Modal  // Register the component
  },
  data() {
    return {
      showModal: false,
      preOrderEmail: '',
      preOrderPhone: '',
      preOrderAgreement: false,
      courseitems: [],
      courseIdMap: [],
    };
  },


  async mounted() {
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/courses');
      this.items = response.data.data;

      this.courseitems = response.data.data.map(item => item.attributes);
      console.log(this.items);

      //  using JavaScript's Array.prototype.reduce method to transform an array (this.items) 
      //into a single object (this.courseIdMap):
      this.courseIdMap = this.items.reduce((map, item, index) => {
        map[index] = item.id;
        return map;
      }, {});


      // console.log(response.data.data);
      console.log(this.courseitems.data);

    } catch (error) {
      console.error(error);
    }
  },
  methods: {



    submitPreOrder() {
      if (!this.preOrderAgreement) {
        alert('Please agree to pre-order terms.');
        return;
      }
      // Handle the pre-order submission logic here
      console.log('Pre-order submitted:', this.preOrderEmail, this.preOrderPhone);
    },
  },

  // -------------Strapi-----------
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>