<template>
  <div class="useable-tools-section-four lg-container">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text-wrapper">
              <div class="title-style-nine">
                <h2>What is inside the platform ?</h2>
              </div>
              <p>Our platform is packed with advanced technology and features designed to enhance the learning experience for students of all ages. From AI-powered personalized microlearning to dynamic resource allocation and optimization, our platform is built to help students achieve their goals and unleash their inner robotics engineer. Explore our platform today and discover the power of robotics education.</p>
              
            </div> <!-- /.text-wrapper -->
          </div>
          <div class="col-xl-5 col-lg-6 ms-auto">
            <div class="logo-container h-100">
              <div class="inner-wrapper">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="line-one">
                      <div class="iconone  align-items-center ">
                        <img src="../../assets/images/icon/1.png" alt="">
                      </div> <!-- /.icon -->
                      <div class="icontree  align-items-center ">
                        <img src="../../assets/images/icon/2.png" alt="">
                      </div> <!-- /.icon -->
                    
                    </div> <!-- /.line-one -->
                  </div>
                  <div class="col-sm-6">
                    <div class="line-two">
                      <div class="icontwo  align-items-center ">
                        <img src="../../assets/images/icon/3.png" alt="">
                      </div> <!-- /.icon -->
                      <div class="iconfour  align-items-center ">
                        <img src="../../assets/images/icon/4.png" alt="">
                      </div> <!-- /.icon -->
                    
                    </div> <!-- /.line-two -->
                  </div>
                </div>
              </div> <!-- /.inner-wrapper -->
            </div> <!-- /.logo-container -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UseableTools'
}
</script>