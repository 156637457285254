<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <!-- header start -->
    <!-- header end -->
    <Header />

    <!-- details area start -->
    <div class="service-details-one pt-150 md-pt-100">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-8 order-lg-last">
            <div v-if="activeContent === 'courses'" class="courses-content">
              <Coursetitels />
              <div class=" fancy-text-block-five details-wrapper ps-xl-5">

                <h3 class="font-gordita pt-90 pb-60 md-pt-50">Any Questions find here.</h3>

                <div id="accordionExample" class="accordion-style-three">

                  <div v-for="item in faqData" :key="item.id" class="accordion-item">
                    <div class="accordion-header" :id="item.headingNum">
                      <h5 class="mb-0">
                        <button class="btn btn-link accordion-button" type="button" data-bs-toggle="collapse"
                          :data-bs-target="'#' + item.collapseNum" :aria-expanded="item.expanded ? 'true' : 'false'"
                          :aria-controls="item.collapseNum">
                          {{ item.btnText }}
                        </button>
                      </h5>
                    </div>
                    <div :id="item.collapseNum" :class="item.collapseClass" :aria-labelledby="item.headingNum"
                      data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>
                          {{ item.desc }}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div v-else-if="activeContent === 'learning'" class="learning-content">
              <!-- Content for Orbital -->
              <!-- <Learning /> -->
              <Enrolled />
            </div>
            <div v-else-if="activeContent === 'simulator'" class="simulator-content">
              <!-- Content for Orbital -->
            <Simulator/>
            </div>
            <div v-else-if="activeContent === 'orbital'" class="orbital-content">
              <!-- Content for Orbital -->
              <Orbital />
            
            </div>
         
            <div v-else-if="activeContent === 'community'" class="community-content">
              <!-- Content for Community -->
              <Commiunity />
            </div>

            <div v-else-if="activeContent === 'profile'" class="profile-content">
              <!-- Content for Profile -->
              <Profile />
            
            </div>
            <!-- /.details-wrapper -->
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 order-lg-first">
            <div class="sidebar md-mt-100">
              <div class="category-list">
                <h4 class="font-gordita dashboard_menue">Menue</h4>
                <ul>
                  <li @click="changeContent('courses')"><img src="../assets/images/icon/course.png"
                      style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'courses' }">Productes</a>
                  </li>
                  <li><a href="#"></a></li>
                  <li @click="changeContent('learning')"><img src="../assets/images/icon/robot.png"
                      style="display: inline;">
                    <a href="#" :class="{ 'active': activeContent === 'learning' }">Learning</a>
                  </li>
                  <li><a href="#"></a></li>
                  <li @click="changeContent('simulator')"><img src="../assets/images/icon/simulation.png"
                      style="display: inline;"> <a href="#" :class="{ 'active': activeContent === 'simulator' }">Online Simulator</a>
                  </li>
                  <li><a href="#"></a></li>
                  <li @click="changeContent('orbital')"><img src="../assets/images/icon/unlock.png"
                      style="display: inline;"> <a href="#" :class="{ 'active': activeContent === 'orbital' }">Orbital</a>
                  </li>
                  <li><a href="#"></a></li>
               
                  <li @click="changeContent('community')"><img src="../assets/images/icon/group.png"
                      style="display: inline;"><a href="#"
                      :class="{ 'active': activeContent === 'community' }">Commiunity</a></li>
                  <li><a href="#"></a></li>

                  <li @click="changeContent('profile')"><img src="../assets/images/icon/user.png"
                      style="display: inline;"><a href="#" :class="{ 'active': activeContent === 'profile' }">Profile </a>
                  </li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                </ul>
              </div> <!-- /.category-list -->
              <div class="sidenote">
                <p>Empowering Your Robotics Journey - Code, Connect, Create!</p>
                <span>- Roboquark</span>
              </div>

            </div> <!-- /.sidebar -->
          </div>
        </div>
      </div>
    </div>
    <!-- details area end -->

    <!-- footer start -->
    <Footer />
    <!-- footer end -->
  </div>
</template>

<script>
import Header from "../components/common/Headers/dashboardheader.vue";
import Footer from "../components/common/Footers/FooterSeven.vue";
import HeaderFive from "../components/common/Headers/HeaderFive.vue";
import Coursetitels from "../components/dashboard/course_titles.vue";
import Orbital from "../components/dashboard/orbital.vue";
import Commiunity from "../components/dashboard/commiunity.vue"
import Profile from "../components/dashboard/profile.vue"
import Learning from "../components/dashboard/doc-area.vue"
import Enrolled from "../components/dashboard/enrolled.vue"
import Simulator from "../components/dashboard/simulator-list.vue"

import axios from 'axios';
// for query from strapi we use this both
import { ref, onMounted } from 'vue';
const courses = ref([]);
// const userInfo = JSON.parse(localStorage.getItem('userInfo'));


export default {
  name: 'Dashboards',
  components: { Footer, Header, Coursetitels, Orbital, Commiunity, Profile, Learning, Enrolled,Simulator },
  setup() {
    const matchedEnrollments = ref([]);
    //userdata who we store in log in page 
    const userinfo = JSON.parse(localStorage.getItem('userInfo'));

    const fetchEnrollments = async () => {
      try {
        // Replace 'http://localhost:1337' with your actual Strapi base URL
        const response = await fetch(process.env.VUE_APP_STRAPI_URL + 'api/enrolleds?filters[username][$eq]=' + userinfo.username);
        console.log("response");

        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('data', data); // Log the actual data

        matchedEnrollments.value = data.data;
        console.log('data', matchedEnrollments.value); // Log the actual data

      } catch (error) {
        console.error('There was a problem retrieving the enrolled data:', error);
      }
    };

    onMounted(() => {
     
        fetchEnrollments();
      
    });

    return {
      matchedEnrollments
    };
  },
  data() {
    return {
      userData: null,
      user: [],
      id: this.$route.params.id,
      service: {},
      activeContent: 'courses',// Set the default active content
      faqData: [
        {
          id: 1,
          headingNum: 'headingOne',
          collapseNum: "collapseOne",
          btnText: "What is your company's mission?",
          desc: 'Our mission is to provide innovative and accessible robotics education and products that empower students and enthusiasts to explore and create in the world of robotics.',
          collapseClass: 'accordion-collapse collapse'
        },
        {
          id: 2,
          headingNum: 'headingTwo',
          collapseNum: "collapseTwo",
          btnText: "What age groups do you cater to?",
          desc: 'We offer educational resources and products for adult learners. +16',
          expanded: true,
          collapseClass: 'accordion-collapse collapse'
        },
        {
          id: 3,
          headingNum: 'headingThree',
          collapseNum: "collapseThree",
          btnText: "How can I get involved with robotics if I have no prior experience?",
          desc: 'Our courses and products are designed to be accessible to beginners with no prior experience in robotics. We offer introductory courses and beginner-friendly kits that can help you get started.',
          collapseClass: 'accordion-collapse collapse'
        },
      
      ]
    }
  },
  methods: {
    // ... change page content ...
    changeContent(content) {
    this.activeContent = content;
    // Update the URL with the new query parameter without reloading the page
    this.$router.push({ path: '/dashboard', query: { content: content } }).catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
    });
  },
   
    // check if user authenticated befor or no
    isAuthenticated() {
    const token = localStorage.getItem('jwt');

    return !!token; // Returns true if token exists, false otherwise
  }
  },
  mounted() {
  // Check for the content query parameter when the component is mounted
  const content = this.$route.query.content;
  if (content) {
    this.activeContent = content;
  } else {
    // Optionally, set a default section if no query parameter is provided
    this.activeContent = 'courses'; // or any default section
  }

  // Check if the user is authenticated
  if (!this.isAuthenticated()) {
    this.$router.push('/login'); // Redirect to login page if not authenticated
  }
},
watch: {
  '$route.query.content'(newContent) {
    if (newContent) {
      this.activeContent = newContent;
    }
  }
},

  created() {
    // this.getService(this.id);
  },


} 
</script>