<template>
  <ul class="mega-menu d-flex">
    <li v-for="item in homes_data" :key="item.id">
      <router-link :to="item.url" class="dropdown-item img-box">
        <img :src="item.img" alt="" />
        <span class="font-rubik">{{item.title}}</span>
      </router-link>
    </li>
   
  </ul>
</template>

<script>
export default {
  name: 'mega-menus',
  data () {

  }
}
</script>