<template>
  <div class="">

    <div class="container">
      <div class="row">
        <div v-for="(courseitem, index) in courseitems" :key="courseitem.id" class=" feature-blog-one ">

          <div v-if="courseitem.title != 'RoboBook - Explore robotics'" class="post-meta row" data-aos="fade-up" data-aos-duration="1200" :data-aos-delay="100">
            <div class="col-4">
              <img src="../../assets/images/assets/bannerpic.png" alt="" class="image-meta">
            </div>
            <div class="col-8" style="margin-top: 6%;">
              <h3 >
                <router-link :to="`/coursedetails?id=${courseIdMap[index]}`" class="title">{{ courseitem.title
                }}.</router-link>
              </h3>
            </div>
            <div class="row course-desc">
              <div class="col-6">
                <p>{{ courseitem.content }}</p>

              </div>
              <div class="col-3">
                <router-link :to="`/coursedetails?id=${courseIdMap[index]}`">

                  <a v-if="courseitem.title !== 'Roboquark Hardware package'" class="theme-btn-four">Enroll
                  </a>
                </router-link>
                <a v-if="courseitem.title == 'Roboquark Hardware package'" class="theme-btn-four" id="show-modal"
                  @click="showModal = true">Pre-Order</a>
                  <Teleport to="body">
                  <!-- use the modal component, pass in the prop -->
                  <Modal :show="showModal" @close="showModal = false">
                   
                  </Modal>
                </Teleport>
              </div>
            </div>
          </div> <!-- /.post-meta -->


        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
// -------------Strapi-----------
import axios from 'axios';
// -------------Strapi-----------
import Modal from '../robo/modal.vue';
import { ref } from 'vue';

const showModal = ref(false);
export default {
  name: 'Coursse_titels',
  // -------------Strapi-----------
  components: {
    Modal  // Register the component
  },
  data() {
    return {
      showModal: false,
      courseitems: [],
      courseIdMap: [],
    };
  },

 
  async mounted() {
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URL + 'api/courses');
      this.items = response.data.data;

      this.courseitems = response.data.data.map(item => item.attributes);

      //  using JavaScript's Array.prototype.reduce method to transform an array (this.items) 
      //into a single object (this.courseIdMap):
      this.courseIdMap = this.items.reduce((map, item, index) => {
        map[index] = item.id;
        return map;
      }, {});


      // console.log(response.data.data);
      console.log(this.courseitems);

    } catch (error) {
      console.error(error);
    }
  },
  // -------------Strapi-----------
}
</script>