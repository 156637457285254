<template>
  <div
    class="fancy-feature-twentySeven lg-container pt-100 pt-20 md-mt-60 md-mb-20"
    id="feature"
  >
    <div class="container">
      <div class="title-style-twelve text-center mb-70 md-mb-20">
        <div class="row">
          <div class="col-xl-10 col-lg-11 m-auto">
            <h2>
              <span
                >The Three Pillars of Robotics 
                <img src="../../assets/images/shape/line-shape-13.svg" alt=""
              /></span>
            </h2>
            <p class="mt-40 md-mt-30">Along with our products, there are free tutorials on how to make robots</p>
            <p class="">
              Our free robotics training program empowers you with the skills you need to create robots. With our guidance, you can master the three essential characteristics that make each robot. Get ready to unleash your creativity and excel in the field of robotics.
Every robot possesses a unique set of three defining traits.            </p>
          </div>
        </div>
      </div>

      <div class="row box">

        <div
          v-for="item in featureData"
          :key="item.id"
          class="col-lg-3 col-sm-6 "
          data-aos="fade-up"
          :data-aos-delay="item.delay"
        >
          <div class="block-style-twentySeven">
            <div class="icon d-flex align-items-end justify-content-center">
              <img :src="item.icon" alt="" />
            </div>
            <h4 class="font-gordita">{{ item.title }}</h4>
            <p>{{ item.subtitle }}</p>
          </div>
          <!-- /.block-style-twentySeven -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeatureTwo",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/me.png`),
          title: "Mechanic",
          subtitle: "Every robot should can respond to its environment. The mechanic has responsibility of ensuring that every robot is fully capable of responding to its environment. ",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/pr.png`),
          title: "Programming",
          subtitle: "Robots operate without human control and can be programmed. Robot software programming enables the self-operation of machines through coding.",

          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/el.png`),
          title: "Electronic",
          subtitle: "Having the ability to receive information from the environment automatically is a crucial feature for any robot; that is the most important part of electronics.",

          delay: "200",
        },
      
      ],
    };
  },
};
</script>
